import { SortDescriptor, Tooltip } from '@nextui-org/react';
import React, {
  Dispatch,
  Key,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { FaInfoCircle } from 'react-icons/fa';
import { IRota } from '../../../interfaces/response/Rotas/IRota';
import { formatarData } from '../../../util/format';
import { cpfMask } from '../../../util/mask';

type Column = {
  key: string;
  name: string;
};

export interface UseRoutesTableProps {
  routes: IRota[];
  onRowClick?: (route: IRota) => void;
}

export interface UseRoutesTable {
  handleRowClick: (key: Key) => void;
  sortDescriptor: SortDescriptor;
  setSortDescriptor: Dispatch<SetStateAction<SortDescriptor>>;
  columns: Column[];
  sortedRoutes: IRota[];
  cellData: (route: IRota, columnKey: Key) => JSX.Element;
}

const useRoutesTable = ({
  routes,
  onRowClick,
}: UseRoutesTableProps): UseRoutesTable => {
  const [sortDescriptor, setSortDescriptor] = useState<SortDescriptor>({
    column: 'idRota',
    direction: 'descending',
  });

  const sortedRoutes = useMemo(() => {
    return [...routes].sort((a: IRota, b: IRota) => {
      const first = a[sortDescriptor.column as keyof IRota] as number;
      const second = b[sortDescriptor.column as keyof IRota] as number;

      let cmp = 0;
      if (first < second) cmp = -1;
      if (first > second) cmp = 1;

      return sortDescriptor.direction === 'ascending' ? -cmp : cmp;
    });
  }, [sortDescriptor, routes]);

  const columns: Column[] = [
    {
      key: 'COR',
      name: 'COR',
    },
    {
      key: 'nome',
      name: 'NOME DA ROTA',
    },
    {
      key: 'dataSaida',
      name: 'DATA DE SAÍDA',
    },
    {
      key: 'Veiculo',
      name: 'VEÍCULO',
    },
    {
      key: 'Motorista',
      name: 'MOTORISTA',
    },
    {
      key: 'Alertas',
      name: 'Alertas',
    },
  ];

  const CreateToolTip = ({
    descricao,
    color,
    idAlerta,
  }: {
    descricao: string;
    color: string;
    idAlerta: string;
  }): JSX.Element => (
    <div key={idAlerta}>
      <Tooltip
        content={<p className="text text-foreground-500">{descricao}</p>}
      >
        <div>
          <FaInfoCircle size={20} color={color} />
        </div>
      </Tooltip>
    </div>
  );

  const cellData = useCallback((route: IRota, columnKey: Key): JSX.Element => {
    switch (columnKey) {
      case 'COR':
        return (
          <div
            style={{
              height: '20px',
              widows: '100%',
              backgroundColor: route.polylines ? route.polylines[0].color : '',
              border: '1px solid #313131',
              borderRadius: '2px',
            }}
          />
        );
      case 'nome':
        return <p className="text-foreground-600">{route.descricao}</p>;
      case 'dataSaida':
        return (
          <p className="text-foreground-600">
            {formatarData(new Date(route.dataSaida), true, 'normal') ?? 'Error'}
          </p>
        );
      case 'Veiculo':
        return (
          <p className="text-foreground-600">{`${route.veiculoMotorista.placa} - ${route.veiculoMotorista.modelo}, ${route.veiculoMotorista.anoModelo}/${route.veiculoMotorista.anoFabricacao}`}</p>
        );
      case 'Motorista':
        return (
          <p className="text-foreground-600">{`${route.veiculoMotorista.nome.toUpperCase()} - ${cpfMask(
            route.veiculoMotorista.cpf
          )}`}</p>
        );
      case 'Alertas':
        return (
          <div className="flex flex-row gap-1 text-foreground-600">
            {route.alertas?.map((alerta) => {
              switch (alerta.indCriticidade) {
                case 1:
                  return CreateToolTip({
                    descricao: alerta.descricao,
                    color: '#fdc92e',
                    idAlerta: alerta.idAlerta,
                  });
                case 2:
                  return CreateToolTip({
                    descricao: alerta.descricao,
                    color: '#fd512e',
                    idAlerta: alerta.idAlerta,
                  });
                default:
                  return CreateToolTip({
                    descricao: alerta.descricao,
                    color: '#6a6a6a',
                    idAlerta: alerta.idAlerta,
                  });
              }
            })}
          </div>
        );
      default:
        return <div />;
    }
  }, []);

  const handleRowClick = (key: Key): void => {
    const route = routes.find((routeAux) => routeAux.idRota.toString() === key);
    if (onRowClick && route) onRowClick(route);
  };

  return {
    cellData,
    columns,
    handleRowClick,
    setSortDescriptor,
    sortDescriptor,
    sortedRoutes,
  };
};

export default useRoutesTable;

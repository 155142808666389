import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { RoutesAPI } from '../../../config/api';
import { IBadRequestResponse } from '../../../interfaces/IBadRequestResponse';
import { IDetalheRotaRequest } from '../../../interfaces/request/DetalheRota/IDetalheRotaRequest';
import { IRotaCriarResponse } from '../../../interfaces/response/Rotas/IRotaCriarResponse';

export const postRoute = createAsyncThunk(
  'rotas/postRoute',
  async (
    args: {
      body: IDetalheRotaRequest;
    },
    { rejectWithValue }
  ): Promise<IRotaCriarResponse | any> => {
    const { body } = args;

    try {
      const { data } = await RoutesAPI.post(`api/v1/Routes`, body);

      return data;
    } catch (err) {
      const error = err as AxiosError<IBadRequestResponse>;

      return rejectWithValue(error);
    }
  }
);

export const getRouteDetails = createAsyncThunk(
  'rotas/getRouteDetails',
  async (
    args: {
      id: string;
    },
    { rejectWithValue }
  ): Promise<IRotaCriarResponse | any> => {
    const { id } = args;

    try {
      const { data } = await RoutesAPI.get(`api/v1/Routes/id/${id}`);

      return data;
    } catch (err) {
      const error = err as AxiosError<IBadRequestResponse>;

      return rejectWithValue(error);
    }
  }
);

export const approveRoute = createAsyncThunk(
  'rotas/approveRoute',
  async (
    args: {
      id: string;
    },
    { rejectWithValue }
  ): Promise<IRotaCriarResponse | any> => {
    const { id } = args;

    try {
      const { data } = await RoutesAPI.post(`api/v1/approval/approve`, {
        IdRota: id,
      });

      return data;
    } catch (err) {
      const error = err as AxiosError<IBadRequestResponse>;

      return rejectWithValue(error);
    }
  }
);

export const rejectRoute = createAsyncThunk(
  'rotas/rejectRoute',
  async (
    args: {
      id: string;
    },
    { rejectWithValue }
  ): Promise<IRotaCriarResponse | any> => {
    const { id } = args;

    try {
      const { data } = await RoutesAPI.post(`api/v1/approval/reprove`, {
        IdRota: id,
      });

      return data;
    } catch (err) {
      const error = err as AxiosError<IBadRequestResponse>;

      return rejectWithValue(error);
    }
  }
);

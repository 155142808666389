import { Button, Spinner } from '@nextui-org/react';
import React from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { FaFileUpload, FaTrash } from 'react-icons/fa';
import { formatarMedidaGenerico } from '../../../../../util/mask';
import useDragDrop from './hooks/useDragDrop';

interface IDragDropProps {
  callback?: () => Promise<void>;
}

const DragDrop = ({ callback }: IDragDropProps): JSX.Element => {
  const { file, fileTypes, handleChange, handleSend, setFile, loading } =
    useDragDrop({
      callback,
    });

  return (
    <div className=" max-w-[330px]">
      <FileUploader
        handleChange={handleChange}
        name="file"
        types={fileTypes}
        multiple={false}
        disabled={file != null}
        hoverTitle="solte aqui"
      >
        {file !== null ? (
          <div className="flex h-[60px] w-[330px] cursor-pointer flex-row items-center gap-4 rounded border-2 border-dashed border-foreground-500 p-2 ">
            <Button
              size="sm"
              type="button"
              color="danger"
              isIconOnly
              onPress={() => setFile(null)}
            >
              <FaTrash className="h-4 w-4 cursor-pointer" />
            </Button>

            <p className="max-w-[300px] overflow-hidden text-ellipsis whitespace-nowrap text-sm text-foreground-700">
              {file.name} {formatarMedidaGenerico(file.size / 1000, 'Kb')}
            </p>

            {loading ? (
              <div className="flex flex-row items-center">
                <Spinner color="default" />
              </div>
            ) : (
              <Button
                size="sm"
                type="button"
                disabled={loading}
                onPress={() => handleSend()}
              >
                Enviar
              </Button>
            )}
          </div>
        ) : (
          <div className="flex h-[60px] w-[330px] cursor-pointer flex-row items-center gap-4 rounded border-2 border-dashed border-foreground-500 p-2 ">
            <Button
              size="sm"
              type="button"
              color="primary"
              isIconOnly
              onPress={() => setFile(null)}
            >
              <FaFileUpload className="h-4 w-4 cursor-pointer" />
            </Button>
            <p className="max-w-[330px] overflow-hidden text-ellipsis whitespace-nowrap text-sm text-foreground-700">
              Clique ou solte aqui o arquivo XML do CTE para importar o Pedido
            </p>
          </div>
        )}
      </FileUploader>
    </div>
  );
};

export default DragDrop;

/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, useEffect, useState } from 'react';
import { IdleTimerProvider } from 'react-idle-timer';
import { logOut } from '../../redux/features/generalData/generalDataSlice';
import { useAsyncDispatch } from '../../redux/store';

const MouseActivityContext = createContext({
  resetTimer: () => {},
});

export interface IProps {
  children?: JSX.Element;
}
const MouseActivityProvider = ({ children }: IProps): JSX.Element => {
  const dispatch = useAsyncDispatch();
  const [isInactive, setIsInactive] = useState(false);

  const logout = async (): Promise<void> => {
    dispatch(logOut());
  };

  useEffect(() => {
    if (isInactive) {
      const timeout = setTimeout(() => {
        logout();
      }, 1000 * 60);
      return () => clearTimeout(timeout);
    }
  }, [isInactive]);

  const onIdle = (): void => {
    setIsInactive(true);
  };
  const onPresenceChange = (): void => {
    setIsInactive(false);
  };
  return (
    <div style={{ width: '100%', height: '100%' }}>
      {children}
      <IdleTimerProvider
        timeout={1000 * 60 * 60 * 2}
        onIdle={onIdle}
        onActive={onPresenceChange}
      />
    </div>
  );
};

export { MouseActivityProvider, MouseActivityContext };

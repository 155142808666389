import { AxiosError } from 'axios';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { IMotorista } from '../../../../interfaces/response/Motorista/IMotorista';
import { ObterMotoristas } from '../../../../redux/features/clientsData/clientsDataThunk';
import { useAsyncDispatch } from '../../../../redux/store';

export interface IProps {
  motoristas: IMotorista[];
  filter: string;
  setFilter: React.Dispatch<React.SetStateAction<string>>;
  loading: boolean;
  handleGetDrivers: () => Promise<void>;
}
export interface IMessageProps {
  mensagem: string;
  show: boolean;
  type: 'success' | 'error';
}

function useMotoristas(): IProps {
  const [filter, setFilter] = useState<string>('');
  const dispatch = useAsyncDispatch();
  const [motoristasFiltrados, setMotoristasFiltrados] = useState<IMotorista[]>(
    []
  );
  const [motoristas, setMotoristas] = useState<IMotorista[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const sortedArray = motoristas.filter((driverAux) =>
      driverAux.nome.toLowerCase().includes(filter.toLowerCase())
    );
    setMotoristasFiltrados(sortedArray);
  }, [filter]);

  const handleGetDrivers = async (): Promise<void> => {
    setLoading(true);

    await dispatch(ObterMotoristas())
      .unwrap()
      .then((response) => {
        setMotoristasFiltrados(response.motoristas);
        setMotoristas(response.motoristas);
      })
      .catch((error) => {
        const axiosError = error as AxiosError;
        if (axiosError.code === 'ERR_CANCELED') return;

        toast.error('Erro ao consultar motoristas, por favor tente mais tarde');
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    handleGetDrivers();
  }, []);

  return {
    motoristas: motoristasFiltrados,
    filter,
    setFilter,
    loading,
    handleGetDrivers,
  };
}

export default useMotoristas;

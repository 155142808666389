import { AxiosError } from 'axios';
import React, { RefObject, useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { RegisterLocationPriceModalHandle } from '../../../../components/RegisterLocationPriceModal/RegisterLocationPriceModal';
import { ITabelaPreco } from '../../../../interfaces/response/TabelaPreco/ITabelaPreco';
import { ITipoVeiculo } from '../../../../interfaces/response/TipoVeiculo/ITipoVeiculo';
import {
  ObterTabelaPreco,
  ObterTipoVeiculo,
} from '../../../../redux/features/clientsData/clientsDataThunk';
import { useAsyncDispatch } from '../../../../redux/store';

export interface IProps {
  locationsPrices: ITabelaPreco[];
  setFilter: React.Dispatch<React.SetStateAction<string>>;
  loading: boolean;
  handleGetLocationsPrice: () => void;
  modalRef: RefObject<RegisterLocationPriceModalHandle>;
  vehicleTypeOptions: ITipoVeiculo[];
}

const useTabelaPreco = (): IProps => {
  const [filter, setFilter] = useState<string>('');
  const modalRef = useRef<RegisterLocationPriceModalHandle>(null);
  const dispatch = useAsyncDispatch();
  const [sortedLocationsPrice, setSortedLocationsPrice] = useState<
    ITabelaPreco[]
  >([]);
  const [locationsPrices, setLocationsPrices] = useState<ITabelaPreco[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [vehicleTypeOptions, setVehicleTypeOptions] = useState<ITipoVeiculo[]>(
    []
  );

  useEffect(() => {
    const sortedArray = locationsPrices.filter((locationPriceAux) =>
      locationPriceAux.cepInicio.toLowerCase().includes(filter.toLowerCase())
    );
    setSortedLocationsPrice(sortedArray);
  }, [filter]);

  const handleGetVehicleTypes = async (): Promise<void> => {
    await dispatch(ObterTipoVeiculo())
      .unwrap()
      .then((response) => setVehicleTypeOptions(response.tipoVeiculos))
      .catch(() => {
        toast.error(
          'Ocorreu um erro ao buscar os tipos de veículo, por favor tente mais tarde.'
        );
      });
  };

  const consultarTabelaPreco = async (): Promise<void> => {
    setLoading(true);

    await dispatch(ObterTabelaPreco())
      .unwrap()
      .then(async (response) => {
        await handleGetVehicleTypes();
        setSortedLocationsPrice(response.tabelasPreco);
        setLocationsPrices(response.tabelasPreco);
      })
      .catch((error) => {
        const axiosError = error as AxiosError;
        if (axiosError.code === 'ERR_CANCELED') return;

        toast.error(
          'Erro ao consultar tabelas preço, por favor tente mais tarde'
        );
      })
      .finally(() => setLoading(false));
  };

  const handleGetLocationsPrice = (): void => {
    consultarTabelaPreco();
  };

  useEffect(() => {
    consultarTabelaPreco();
  }, []);

  return {
    locationsPrices: sortedLocationsPrice,
    setFilter,
    loading,
    modalRef,
    vehicleTypeOptions,
    handleGetLocationsPrice,
  };
};

export default useTabelaPreco;

import { zodResolver } from '@hookform/resolvers/zod';
import { useDisclosure } from '@nextui-org/react';
import { Key, useContext, useEffect, useState } from 'react';
import {
  Control,
  FieldErrors,
  useForm,
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { z } from 'zod';
import {
  Theme,
  ThemeContext,
} from '../../../contexts/ThemeContext/ThemeContext';
import { ICadastraVeiculoRequest } from '../../../interfaces/request/Veiculo/ICadastraVeiculoRequest';
import { IEditaVeiculoRequest } from '../../../interfaces/request/Veiculo/IEditaVeiculoRequest';
import { ITipoVeiculo } from '../../../interfaces/response/TipoVeiculo/ITipoVeiculo';
import { IVeiculo } from '../../../interfaces/response/Veiculo/IVeiculo';
import {
  CadastrarVeiculo,
  EditarVeiculo,
  ObterTipoVeiculo,
} from '../../../redux/features/clientsData/clientsDataThunk';
import { selectUsuario } from '../../../redux/features/generalData/generalDataSelectors';
import { useAsyncDispatch } from '../../../redux/store';
import { moneyMask } from '../../../util/mask';

const formSchema = z.object({
  plate: z
    .string()
    .min(1, 'A placa é obrigatória')
    .length(7, 'Placa deve ter 4 letras e 3 números'),
  doc: z.string().min(1, 'DOC é obrigatório'),
  antt: z.string().min(1, 'ANTT é obrigatório'),
  vehicleType: z.string().min(1, 'Tipo de veículo é obrigatório'),
  value: z
    .string()
    .min(1, 'Valor é obrigatório')
    .refine(
      (value) => Number(value.replaceAll('.', '').replace(',', '.')) > 0,
      'Valor precisa ser maior que zero'
    ),
  weight: z
    .string()
    .min(1, 'Peso é obrigatório')
    .refine(
      (value) => Number(value.replaceAll('.', '').replace(',', '.')) > 0,
      'Peso precisa ser maior que zero'
    ),
  diameter: z
    .string()
    .min(1, 'Diâmetro é obrigatório')
    .refine(
      (value) => Number(value.replaceAll('.', '').replace(',', '.')) > 0,
      'Diâmetro precisa ser maior que zero'
    ),
  height: z
    .string()
    .min(1, 'Altura é obrigatória')
    .refine(
      (value) => Number(value.replaceAll('.', '').replace(',', '.')) > 0,
      'Altura precisa ser maior que zero'
    ),
  width: z
    .string()
    .min(1, 'Largura é obrigatória')
    .refine(
      (value) => Number(value.replaceAll('.', '').replace(',', '.')) > 0,
      'Largura precisa ser maior que zero'
    ),
  licensingDate: z
    .date({
      invalid_type_error: 'Data de licenciamento é necessária',
      required_error: 'Data de licenciamento é necessária',
    })
    .min(new Date(), 'Data de licenciamento deve ser maior que a atual'),
  researcDate: z
    .date({
      invalid_type_error: 'Data de pesquisa é necessária',
      required_error: 'Data de pesquisa é necessária',
    })
    .min(new Date(), 'Data de pesquisa deve ser maior que a atual'),
  brand: z.string().optional(),
  model: z.string().optional(),
  version: z.string().optional(),
  manufactureYear: z.date().optional(),
  modelYear: z.date().optional(),
});

type FormSchema = z.infer<typeof formSchema>;

interface UseRegisterLocationPriceModalProps {
  onUpdate?: () => void;
}

export interface UseRegisterLocationPriceModal {
  errors: FieldErrors<FormSchema>;
  handleSubmitVehicle: (data: FormSchema) => Promise<void>;
  handleClose: () => void;
  handleSubmit: UseFormHandleSubmit<FormSchema>;
  handleOpenEdit: (vehicleAux: IVeiculo) => void;
  isLoading: boolean;
  isOpen: boolean;
  isValid: boolean;
  onOpen: () => void;
  onOpenChange: () => void;
  register: UseFormRegister<FormSchema>;
  theme: Theme;
  control: Control<FormSchema>;
  setValue: UseFormSetValue<FormSchema>;
  values: FormSchema;
  vehicleTypeOptions: ITipoVeiculo[];
  vehicle: IVeiculo | null;
  handleVehicleSelected: (key: Iterable<Key>) => void;
}

const useRegisterLocationPriceModal = ({
  onUpdate,
}: UseRegisterLocationPriceModalProps): UseRegisterLocationPriceModal => {
  const dispatch = useAsyncDispatch();
  const { isOpen, onOpen, onClose, onOpenChange } = useDisclosure();
  const { theme } = useContext(ThemeContext);
  const user = useSelector(selectUsuario);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [vehicle, setVehicle] = useState<IVeiculo | null>(null);
  const [vehicleTypeOptions, setVehicleTypeOptions] = useState<ITipoVeiculo[]>(
    []
  );
  const defaultValues: Partial<FormSchema> = {
    antt: '',
    diameter: '0,00',
    doc: '',
    height: '0,00',
    licensingDate: undefined,
    plate: '',
    researcDate: undefined,
    weight: '0,00',
    width: '0,00',
    value: '0,00',
    vehicleType: '',
    brand: '',
    manufactureYear: undefined,
    modelYear: undefined,
    model: '',
    version: '',
  };
  const {
    register,
    handleSubmit,
    clearErrors,
    formState,
    reset,
    control,
    setValue,
    watch,
  } = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
    defaultValues,
    mode: 'onTouched',
    reValidateMode: 'onChange',
    resetOptions: {
      keepDefaultValues: true,
    },
  });
  const { errors, isValid } = formState;
  const values = watch();

  const handleClose = (): void => {
    onClose();
    setVehicle(null);
    clearErrors();
    reset();
  };

  const handleCreateVehicle = async (data: FormSchema): Promise<void> => {
    if (!user) return;

    const { _Id: userId } = user;
    const {
      antt,
      diameter,
      doc,
      height,
      licensingDate,
      plate,
      researcDate,
      value,
      vehicleType,
      weight,
      width,
      brand,
      manufactureYear,
      model,
      modelYear,
      version,
    } = data;

    const body: ICadastraVeiculoRequest = {
      idDeposito: user.defaultWarehouse.idDeposito,
      anoFabricacao: manufactureYear
        ? manufactureYear.getFullYear().toString()
        : null,
      anoModelo: modelYear ? modelYear.getFullYear().toString() : null,
      cubagem_Altura: Number(height.replaceAll('.', '').replace(',', '.')),
      cubagem_Diametro: Number(diameter.replaceAll('.', '').replace(',', '.')),
      cubagem_Largura: Number(width.replaceAll('.', '').replace(',', '.')),
      dataPesquisa: researcDate,
      dataUltimoLicenciamento: licensingDate,
      marca: brand ?? null,
      modelo: model ?? null,
      nrDoc: doc,
      numeroANTT: antt,
      peso: Number(weight.replaceAll('.', '').replace(',', '.')),
      placa: plate,
      versao: version ?? null,
      idTipoVeiculo: Number(vehicleType),
      valor: Number(value.replaceAll('.', '').replace(',', '.')),
      usuarioCadastro: userId,
    };

    setIsLoading(true);
    await dispatch(
      CadastrarVeiculo({
        body,
      })
    )
      .unwrap()
      .then(() => {
        toast.success('Veículo cadastrado com sucesso!');
        handleClose();
        if (onUpdate) onUpdate();
      })
      .catch(() => {
        toast.error(
          'Ocorreu um erro ao realizar o cadastro do veículo, por favor tente mais tarde.'
        );
      })
      .finally(() => setIsLoading(false));
  };

  const handleEditVehicle = async (data: FormSchema): Promise<void> => {
    if (!user || !vehicle) return;

    const { _Id: userId } = user;
    const {
      antt,
      diameter,
      doc,
      height,
      licensingDate,
      plate,
      researcDate,
      value,
      vehicleType,
      weight,
      width,
      brand,
      manufactureYear,
      model,
      modelYear,
      version,
    } = data;

    const body: IEditaVeiculoRequest = {
      anoFabricacao: manufactureYear
        ? manufactureYear.getFullYear().toString()
        : '',
      anoModelo: modelYear ? modelYear.getFullYear().toString() : '',
      cubagem_Altura: Number(height.replaceAll('.', '').replace(',', '.')),
      cubagem_Diametro: Number(diameter.replaceAll('.', '').replace(',', '.')),
      cubagem_Largura: Number(width.replaceAll('.', '').replace(',', '.')),
      dataPesquisa: researcDate,
      dataUltimoLicenciamento: licensingDate,
      marca: brand ?? '',
      modelo: model ?? '',
      nrDoc: doc,
      numeroANTT: antt,
      peso: Number(weight.replaceAll('.', '').replace(',', '.')),
      placa: plate,
      versao: version ?? '',
      idTipoVeiculo: Number(vehicleType),
      valor: Number(value.replaceAll('.', '').replace(',', '.')),
      usuarioAlteracao: userId,
      idVeiculo: vehicle.idVeiculo,
    };

    setIsLoading(true);
    await dispatch(
      EditarVeiculo({
        body,
      })
    )
      .unwrap()
      .then(() => {
        toast.success('Veículo editado com sucesso!');
        handleClose();
        if (onUpdate) onUpdate();
      })
      .catch(() => {
        toast.error(
          'Ocorreu um erro ao realizar a alteração do veículo, por favor tente mais tarde.'
        );
      })
      .finally(() => setIsLoading(false));
  };

  const handleSubmitVehicle = async (data: FormSchema): Promise<void> => {
    if (vehicle) handleEditVehicle(data);
    else handleCreateVehicle(data);
  };

  const handleGetVehicleTypes = async (): Promise<void> => {
    await dispatch(ObterTipoVeiculo())
      .unwrap()
      .then((response) => setVehicleTypeOptions(response.tipoVeiculos))
      .catch(() => {
        handleClose();
        toast.error(
          'Ocorreu um erro ao buscar os tipos de veículo, por favor tente mais tarde.'
        );
      });
  };

  useEffect(() => {
    handleGetVehicleTypes();
  }, []);

  const handleOpenEdit = (vehicleAux: IVeiculo): void => {
    setVehicle(vehicleAux);
    setValue('plate', vehicleAux?.placa ?? '');
    setValue('doc', vehicleAux?.nrDoc ?? '');
    setValue('antt', vehicleAux?.numeroANTT ?? '');
    setValue('weight', moneyMask(vehicleAux?.peso, '', 2) ?? '');
    setValue('height', moneyMask(vehicleAux?.cubagem_Altura, '', 2) ?? '');
    setValue('width', moneyMask(vehicleAux?.cubagem_Largura, '', 2) ?? '');
    setValue('diameter', moneyMask(vehicleAux?.cubagem_Diametro, '', 2) ?? '');
    setValue('brand', vehicleAux?.marca ?? '');
    setValue('model', vehicleAux?.modelo ?? '');
    setValue('version', vehicleAux?.versao ?? '');
    setValue('manufactureYear', new Date(vehicleAux?.anoFabricacao));
    setValue('modelYear', new Date(vehicleAux?.anoModelo));
    setValue('licensingDate', new Date(vehicleAux?.dataUltimoLicenciamento));
    setValue('researcDate', new Date(vehicleAux?.dataPesquisa));
    setValue('value', moneyMask(vehicleAux?.valor, '', 2) ?? '');
    setValue('vehicleType', vehicleAux?.idTipoVeiculo.toString() ?? '');
    onOpen();
  };

  const handleVehicleSelected = (key: Iterable<Key>): void => {
    const vehicleKey = Array.from(key)[0];
    const selectedVehicle = vehicleTypeOptions.find(
      (vehicleAux) => vehicleAux.idTipoVeiculo === Number(vehicleKey)
    );
    if (!selectedVehicle) return;

    const {
      cubagem_Altura: height,
      cubagem_Diametro: diameter,
      cubagem_Largura: width,
      peso: weight,
      valor: value,
    } = selectedVehicle;

    setValue('height', moneyMask(height, '', 2));
    setValue('diameter', moneyMask(diameter, '', 2));
    setValue('width', moneyMask(width, '', 2));
    setValue('weight', moneyMask(weight, '', 2));
    setValue('width', moneyMask(width, '', 2));
    setValue('value', moneyMask(value, '', 2));
  };

  return {
    errors,
    handleSubmitVehicle,
    handleClose,
    handleSubmit,
    handleOpenEdit,
    isLoading,
    isOpen,
    isValid,
    onOpen,
    onOpenChange,
    register,
    theme,
    control,
    setValue,
    values,
    vehicleTypeOptions,
    vehicle,
    handleVehicleSelected,
  };
};

export default useRegisterLocationPriceModal;

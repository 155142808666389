import { Wrapper } from '@googlemaps/react-wrapper';
import { Spinner } from '@nextui-org/react';
import { AnimatePresence } from 'framer-motion';
import React from 'react';
import CreatedRouteDetails from './components/CreatedRouteDetails/CreatedRouteDetail';
import ConfirmationModal from './components/CreateRouteAccordion/components/ConfirmationModal/ConfirmationModal';
import CreateRouteAccordion from './components/CreateRouteAccordion/CreateRouteAccordion';
import CreateRouteMap from './components/CreateRouteMap/CreateRouteMap';
import SelectedPeriodCard from './components/SelectionPeriodCard/SelectedPeriodCard';
import useCreateRoutes from './hooks/useCreateRoutes';

const CreateRoutes = (): JSX.Element => {
  const {
    driver,
    orders,
    selectedOrders,
    toggleOrderSelection,
    toggleOrderSelectionManyAdd,
    toggleOrderSelectionManyDelete,
    setDriver,
    setVehicle,
    vehicle,
    warehouse,
    getOrders,
    loadingOrders,
    routeErrors,
    confirmModalRef,
    setRouteErrors,
    routeDetails,
    isLoadingRouteDetails,
    strokeColor,
    returnWarehouse,
    setReturnWarehouse,
  } = useCreateRoutes();

  return (
    <Wrapper
      apiKey={process.env.REACT_APP_MAP_API_KEY ?? ''}
      libraries={['marker', 'drawing']}
      version="beta"
    >
      <div className="relative">
        {/* Confirm Action Accordion */}
        <ConfirmationModal ref={confirmModalRef} routeErrors={routeErrors} />

        {/* Create Route Accordion */}
        <div className="absolute left-2 top-2 z-10">
          <AnimatePresence>
            {selectedOrders.length > 0 && (
              <CreateRouteAccordion
                routeDetails={routeDetails}
                isLoadingRouteDetails={isLoadingRouteDetails}
                updateDriver={setDriver}
                updateVehicle={setVehicle}
                updateReturnWarehouse={setReturnWarehouse}
                onRouteCreated={() => getOrders()}
                warehouse={warehouse}
                routeErrors={routeErrors}
                confirmModalRef={confirmModalRef}
              />
            )}
          </AnimatePresence>
        </div>

        {/* Selected Route Details */}
        <div className="absolute right-2 top-2 z-10">
          <AnimatePresence>
            {selectedOrders.length > 0 && (
              <CreatedRouteDetails
                selectedOrders={selectedOrders}
                backToWarehouse={returnWarehouse}
                warehouse={warehouse}
                vehicle={vehicle}
                driver={driver}
                onErrors={setRouteErrors}
              />
            )}
          </AnimatePresence>
        </div>

        {/* Selected Period Details */}
        <div className="absolute bottom-2 left-2 z-10">
          <SelectedPeriodCard />
        </div>
        {/* Create Route Map */}
        {!loadingOrders ? (
          <CreateRouteMap
            strokeColor={strokeColor}
            routeDetails={routeDetails}
            orders={orders}
            warehouse={warehouse}
            toggleOrderSelection={toggleOrderSelection}
            toggleOrderSelectionManyAdd={toggleOrderSelectionManyAdd}
            toggleOrderSelectionManyDelete={toggleOrderSelectionManyDelete}
            selectedOrders={selectedOrders}
          />
        ) : (
          <div className="flex h-[calc(100vh-65px)] items-center justify-center">
            <Spinner label="Baixando Pedidos" />
          </div>
        )}
      </div>
    </Wrapper>
  );
};

export default CreateRoutes;

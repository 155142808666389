import { AxiosError } from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { RegisterVehicleTypeModalHandle } from '../../../../components/RegisterVehicleTypeModal/RegisterVehicleTypeModal';
import { ITipoVeiculo } from '../../../../interfaces/response/TipoVeiculo/ITipoVeiculo';
import { ObterTipoVeiculo } from '../../../../redux/features/clientsData/clientsDataThunk';
import { useAsyncDispatch } from '../../../../redux/store';

export interface IProps {
  vehicleTypes: ITipoVeiculo[];
  setFilter: React.Dispatch<React.SetStateAction<string>>;
  loading: boolean;
  handleGetVehicleTypes: () => void;
  modalRef: React.RefObject<RegisterVehicleTypeModalHandle>;
}

function useTipoVeiculo(): IProps {
  const [filter, setFilter] = useState<string>('');
  const modalRef = useRef<RegisterVehicleTypeModalHandle>(null);
  const dispatch = useAsyncDispatch();
  const [sortedVehicleType, setSortedVehicleType] = useState<ITipoVeiculo[]>(
    []
  );
  const [vehicleTypes, setVehicleTypes] = useState<ITipoVeiculo[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const sortedArray = vehicleTypes.filter((vehicleAux) =>
      vehicleAux.categoria.toLowerCase().includes(filter.toLowerCase())
    );
    setSortedVehicleType(sortedArray);
  }, [filter]);

  const consultarTipoVeiculo = async (): Promise<void> => {
    setLoading(true);

    await dispatch(ObterTipoVeiculo())
      .unwrap()
      .then(async (response) => {
        setSortedVehicleType(response.tipoVeiculos);
        setVehicleTypes(response.tipoVeiculos);
      })
      .catch((error) => {
        const axiosError = error as AxiosError;
        if (axiosError.code === 'ERR_CANCELED') return;

        toast.error(
          'Erro ao consultar os tipos de veículos, por favor tente mais tarde'
        );
      })
      .finally(() => setLoading(false));
  };

  const handleGetVehicleTypes = (): void => {
    consultarTipoVeiculo();
  };

  useEffect(() => {
    consultarTipoVeiculo();
  }, []);

  return {
    handleGetVehicleTypes,
    loading,
    modalRef,
    setFilter,
    vehicleTypes: sortedVehicleType,
  };
}

export default useTipoVeiculo;

import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Select,
  SelectItem,
  Switch,
  Tooltip,
} from '@nextui-org/react';
import React, { FC } from 'react';
import { Controller } from 'react-hook-form';
import { IoMdAddCircle } from 'react-icons/io';
import { IVeiculo } from '../../interfaces/response/Veiculo/IVeiculo';
import useAddVehicleModal from './hooks/useAddVehicleModal';

interface AddVehicleModalProps {
  idMotorista: number;
  vehicleOptions: IVeiculo[];
  onUpdate: () => void;
}

const AddVehicleModal: FC<AddVehicleModalProps> = ({
  vehicleOptions,
  idMotorista,
  onUpdate,
}): JSX.Element => {
  const {
    control,
    handleAddVehicle,
    handleClose,
    handleSubmit,
    isLoading,
    isOpen,
    isValid,
    onOpen,
    register,
    theme,
  } = useAddVehicleModal({ idMotorista, onUpdate });

  return (
    <>
      <Tooltip
        className={theme}
        content={
          <p className="p-2 text-foreground-700">
            Não existem mais veículos para atribuir a este motorista
          </p>
        }
        isDisabled={vehicleOptions.length !== 0}
      >
        <div>
          <Button
            onPress={onOpen}
            size="md"
            startContent={<IoMdAddCircle />}
            isDisabled={vehicleOptions.length === 0}
          >
            Adicionar veículo
          </Button>
        </div>
      </Tooltip>

      <Modal isOpen={isOpen} className={theme} onClose={handleClose}>
        <ModalContent>
          <form onSubmit={handleSubmit(handleAddVehicle)}>
            <ModalHeader className="text-default-600">
              Adicionar veículo
            </ModalHeader>

            <ModalBody className="gap-4">
              <p className="text-sm text-default-500">
                Selecione o veículo a ser adicionado:
              </p>

              {/* Select */}
              <Controller
                control={control}
                name="vehicle"
                render={({ field }) => (
                  <Select
                    {...field}
                    label="Veículo"
                    variant="underlined"
                    size="md"
                    placeholder="Selecione um veículo"
                    classNames={{
                      label: 'font-bold',
                    }}
                    selectedKeys={undefined}
                    onBlur={field.onBlur}
                    className={`${theme}`}
                    onSelectionChange={field.onChange}
                  >
                    {vehicleOptions.map(({ idVeiculo, placa, nrDoc }) => (
                      <SelectItem
                        key={idVeiculo}
                        value={idVeiculo}
                        textValue={`${placa} - ${nrDoc}`}
                      >
                        <p>
                          <span className="font-semibold">{placa}</span> -{' '}
                          {nrDoc}
                        </p>
                      </SelectItem>
                    ))}
                  </Select>
                )}
              />

              {/* Switch */}
              <div className="flex flex-row items-center justify-center gap-4">
                <p className="text-sm text-default-600">
                  Tornar este veículo o padrão:
                </p>

                <Controller
                  control={control}
                  name="isDefault"
                  render={({
                    field: { onBlur, onChange, value, disabled },
                  }) => (
                    <Switch
                      size="sm"
                      {...register('isDefault')}
                      color="default"
                      isSelected={value}
                      onBlur={onBlur}
                      onChange={onChange}
                      isDisabled={disabled}
                    />
                  )}
                />
              </div>
            </ModalBody>

            <ModalFooter>
              <Button color="danger" onPress={handleClose}>
                Cancelar
              </Button>

              <Button type="submit" isLoading={isLoading} isDisabled={!isValid}>
                Adicionar
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddVehicleModal;

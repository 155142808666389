import React from 'react';
import { Toaster } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { setMiddlewareAPIToken } from '../../config/api';
import { selectUsuario } from '../../redux/features/generalData/generalDataSelectors';

const AuthLayout = (): JSX.Element => {
  const user = useSelector(selectUsuario);

  // Set token if it exists
  setMiddlewareAPIToken(user?.accessToken?.token ?? '');

  return (
    <div className="h-screen">
      <Outlet />

      <Toaster />
    </div>
  );
};

export default AuthLayout;

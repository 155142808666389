/* eslint-disable react/no-array-index-key */
import { Spinner } from '@nextui-org/react';
import React from 'react';
import DatePickerInput from '../../../components/DatepickerInput/DatepickerInput';
import DragDrop from './components/DragDrop/DragDrop';
import SpreadsheetListTable from './components/SpreadsheetListTable/SpreadsheetListTable';
import usePlanilhas from './hooks/usePlanilhas';

const Planilhas = (): JSX.Element => {
  const {
    loading,
    planilhas,
    control,
    endDate,
    startDate,
    consultarMotoristas,
  } = usePlanilhas();

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-row items-center justify-between">
        <div>
          {/* Description text */}
          <p className="text-md text-default-800">
            Selecione o período de importação das planilhas:
          </p>

          {/* Date Filter */}
          <div className="flex flex-row gap-4">
            <DatePickerInput
              control={control}
              name="startDate"
              datePickerProps={{
                selectsStart: true,
                startDate,
                endDate,
                maxDate: endDate,
              }}
              inputProps={{
                size: 'md',
                label: 'De',
                variant: 'underlined',
              }}
            />

            <DatePickerInput
              control={control}
              name="endDate"
              datePickerProps={{
                selectsEnd: true,
                startDate,
                endDate,
                minDate: startDate,
              }}
              inputProps={{
                size: 'md',
                label: 'até',
                variant: 'underlined',
              }}
            />
          </div>
        </div>

        <DragDrop callback={() => consultarMotoristas()} />
      </div>

      {/* Spinner */}
      {loading && (
        <Spinner label="Baixando conteúdo" color="default" className="w-full" />
      )}

      {/* Table */}
      {!loading && <SpreadsheetListTable spreadsheets={planilhas} />}
    </div>
  );
};

export default Planilhas;

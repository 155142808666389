import { AxiosError } from 'axios';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { Deposito } from '../../../interfaces/IUsuario';
import { IDetalhesRota } from '../../../interfaces/response/DetalheRota/IDetalheRotaResponse';
import { IMotorista } from '../../../interfaces/response/Motorista/IMotorista';
import { IDadosPedido } from '../../../interfaces/response/Pedido/IDadosPedido';
import { IPedidosRotasResponse } from '../../../interfaces/response/Pedido/IPedidosRotasResponse';
import { IVeiculoMotorista } from '../../../interfaces/response/VeiculoMotorista/IVeiculoMotorista';
import { ObterEnderecoPedidos } from '../../../redux/features/clientsData/clientsDataThunk';
import {
  selectOrdersDate,
  selectRouteDetails,
  selectUsuario,
} from '../../../redux/features/generalData/generalDataSelectors';
import { setRouteDetails } from '../../../redux/features/generalData/generalDataSlice';
import { useAsyncDispatch } from '../../../redux/store';
import getRandomHexColor from '../../../util/colorGenerator';
import { formatarData } from '../../../util/format';
import { ConfirmationModalHandle } from '../components/CreateRouteAccordion/components/ConfirmationModal/ConfirmationModal';

export interface RouteError {
  errorCode: 'driverMaxOrders' | 'vehicleWeight' | 'vehicleValue';
  message: string;
}

export interface UseCreateRoutes {
  selectedOrders: IPedidosRotasResponse[];
  warehouse: Deposito | undefined;
  vehicle: IVeiculoMotorista | undefined;
  driver: IMotorista | undefined;
  returnWarehouse: boolean;
  orders: IPedidosRotasResponse[];
  toggleOrderSelection: (order: IPedidosRotasResponse) => void;
  toggleOrderSelectionManyAdd: (orders: IPedidosRotasResponse[]) => void;
  toggleOrderSelectionManyDelete: (orders: IPedidosRotasResponse[]) => void;
  setDriver: Dispatch<SetStateAction<IMotorista | undefined>>;
  setVehicle: Dispatch<SetStateAction<IVeiculoMotorista | undefined>>;
  setReturnWarehouse: Dispatch<SetStateAction<boolean>>;
  getOrders: () => Promise<void>;
  loadingOrders: boolean;
  routeErrors: Set<RouteError>;
  setRouteErrors: Dispatch<SetStateAction<Set<RouteError>>>;
  confirmModalRef: React.RefObject<ConfirmationModalHandle>;
  isLoadingRouteDetails: boolean;
  routeDetails: IDetalhesRota | undefined;
  strokeColor: string;
}

const useCreateRoutes = (): UseCreateRoutes => {
  const dispatch = useAsyncDispatch();
  const [loadingOrders, setLoadingOrders] = useState(true);
  const { startOrdersDate, endOrdersDate, searchType } =
    useSelector(selectOrdersDate);
  const user = useSelector(selectUsuario);
  const confirmModalRef = useRef<ConfirmationModalHandle>(null);
  const [orders, setOrders] = useState<IPedidosRotasResponse[]>([]);
  const [warehouse, setWarehouse] = useState<Deposito>();
  const [driver, setDriver] = useState<IMotorista | undefined>(undefined);
  const [returnWarehouse, setReturnWarehouse] = useState<boolean>(true);
  const [vehicle, setVehicle] = useState<IVeiculoMotorista | undefined>(
    undefined
  );
  const [selectedOrders, setSelectedOrders] = useState<IPedidosRotasResponse[]>(
    []
  );
  const [routeErrors, setRouteErrors] = useState<Set<RouteError>>(new Set());
  const [strokeColor, setStrokeColor] = useState<string>('#2d81a3');
  const { routeDetails, isLoadingRouteDetails } =
    useSelector(selectRouteDetails);

  const getOrders = async (): Promise<void> => {
    setLoadingOrders(true);
    await dispatch(
      ObterEnderecoPedidos({
        dataInicio: formatarData(startOrdersDate, true, 'input') ?? '',
        dataFim: formatarData(endOrdersDate, true, 'input') ?? '',
        searchType,
      })
    )
      .unwrap()
      .then((response: IDadosPedido[]) => {
        setSelectedOrders([]);

        const ordersNew: IPedidosRotasResponse[] = [];

        response.forEach((orderRoot) =>
          ordersNew.push({
            pedido: orderRoot.pedido,
            destinatario: orderRoot.destinatario,
            // selecionado: false,
          })
        );

        setOrders(ordersNew);
      })
      .catch((error) => {
        const axiosError = error as AxiosError;
        if (axiosError.code === 'ERR_CANCELED') return;

        toast.error('Erro ao carregar pedidos, por favor tente mais tarde');
      })
      .finally(() => setLoadingOrders(false));
  };

  useEffect(() => {
    setWarehouse(user?.defaultWarehouse);
    getOrders();
    setStrokeColor(getRandomHexColor());
  }, []);

  const setRouteDetailsUndefinedHandler = async (): Promise<void> => {
    await dispatch(
      setRouteDetails({
        routeDetails: undefined,
      })
    );
  };

  // Erase details on no orders selected
  useEffect(() => {
    if (selectedOrders.length === 0) setRouteDetailsUndefinedHandler();
  }, [selectedOrders]);

  const toggleOrderSelection = (order: IPedidosRotasResponse): void => {
    // if (selectedOrders.length >= 25 && !selectedOrders.includes(order)) {
    //   toast.error('Numero máximo de pedidos selecionado');
    //   return;
    // }
    if (!selectedOrders.includes(order)) {
      setSelectedOrders((prev) => [...prev, order]);
    } else {
      setSelectedOrders((prev) => [
        ...prev.filter(
          (orderAux) => orderAux.pedido.idCte !== order.pedido.idCte
        ),
      ]);
    }
  };

  const toggleOrderSelectionManyAdd = (
    pedidos: IPedidosRotasResponse[]
  ): void => {
    let selectedAux = selectedOrders;

    pedidos.forEach((order) => {
      const exists = selectedAux.some(
        (selectedOrder) => selectedOrder.pedido.idCte === order.pedido.idCte
      );
      if (!exists) {
        selectedAux = [...selectedAux, order];
      }
    });

    setSelectedOrders(selectedAux);
  };

  const toggleOrderSelectionManyDelete = (
    pedidos: IPedidosRotasResponse[]
  ): void => {
    let selectedAux = selectedOrders;

    pedidos.forEach((order) => {
      selectedAux = [
        ...selectedAux.filter(
          (orderAux) => orderAux.pedido.idCte !== order.pedido.idCte
        ),
      ];
    });

    setSelectedOrders(selectedAux);
  };

  return {
    driver,
    orders,
    selectedOrders,
    toggleOrderSelection,
    toggleOrderSelectionManyAdd,
    toggleOrderSelectionManyDelete,
    vehicle,
    setDriver,
    setVehicle,
    warehouse,
    getOrders,
    loadingOrders,
    routeErrors,
    setRouteErrors,
    confirmModalRef,
    routeDetails,
    isLoadingRouteDetails,
    strokeColor,
    returnWarehouse,
    setReturnWarehouse,
  };
};

export default useCreateRoutes;

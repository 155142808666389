import React, { FC } from 'react';
import Barcode from 'react-barcode';
import { IOrderCheckResponse } from '../../../../../../interfaces/response/Orders/IOrderCheckResponse';

export interface OrderTagProps {
  tagData: IOrderCheckResponse;
}

const OrderTag: FC<OrderTagProps> = ({ tagData }): JSX.Element => {
  return (
    <div className="grid h-full grid-cols-2 bg-white p-2 light">
      {/* Client Name */}
      <div className="h-[68px]">
        <p className="font-semibold">Destinatário:</p>
        <p className="text-tiny text-default-800">{tagData.destinatario}</p>
      </div>

      {/* Route */}
      <div className="h-[68px]">
        <p className="font-semibold">Rota:</p>
        <p className="text-default-800">{tagData.nomeRota}</p>
      </div>

      {/* Volumes */}
      <div className="h-[68px]">
        <p className="font-semibold">Qtd. de volumes:</p>
        <p className="text-default-800">
          {tagData.qtVolumeConferido}/{tagData.qtVOlumesTotal}
        </p>
      </div>

      {/* Order */}
      <div className="h-[68px]">
        <p className="font-semibold">Ordem de entrega:</p>
        <p className="text-default-800">{tagData.ordemEntrega}</p>
      </div>

      {/* NF-e */}
      <div className="col-span-2 flex h-[50px]">
        <p className="font-mono text-2xl text-default-800">
          <span className="font-semibold">CTE: </span>
          {tagData.nrCTE}
        </p>
      </div>

      {/* Barcode */}
      <div className="col-span-2 flex h-[76px] justify-center">
        <Barcode
          value={tagData.chaveNFe}
          width={1.2}
          height={40}
          fontSize={12}
          background=""
        />
      </div>
    </div>
  );
};

export default OrderTag;

import {
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from '@nextui-org/react';
import React from 'react';
import { IDadosPedido } from '../../../../../interfaces/response/Pedido/IDadosPedido';
import useOrderListTable from './hooks/useOrderListTable';

export interface IOrderListTable {
  orders: IDadosPedido[];
  setOpenModalDetails: (pedido: IDadosPedido) => void;
}

const OrderListTable: React.FC<IOrderListTable> = ({
  orders,
  setOpenModalDetails,
}): JSX.Element => {
  const { cellData, columns, setSortDescriptor, sortDescriptor, sortedOrders } =
    useOrderListTable({
      orders,
    });

  return (
    <Table
      aria-label="Tabela da rota selecionada"
      isHeaderSticky
      selectionMode="single"
      classNames={{
        wrapper: ['xl:max-h-[60vh]', 'max-h-[50vh]'],
      }}
      sortDescriptor={sortDescriptor}
      onSortChange={setSortDescriptor}
    >
      <TableHeader columns={columns}>
        {({ key, name }) => (
          <TableColumn key={key} allowsSorting>
            {name}
          </TableColumn>
        )}
      </TableHeader>

      <TableBody items={sortedOrders} emptyContent="Nenhuma rota no momento">
        {(item) => (
          <TableRow
            key={item.pedido.idCte}
            className="cursor-pointer"
            onClick={() => setOpenModalDetails(item)}
          >
            {(columnKey) => (
              <TableCell align="center">
                <p className="whitespace-nowrap text-foreground-600">
                  {cellData(item, columnKey as any)}
                </p>
              </TableCell>
            )}
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

export default OrderListTable;

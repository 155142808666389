import React, { FC } from 'react';
import { IPedidosRotasResponse } from '../../../../interfaces/response/Pedido/IPedidosRotasResponse';
import { formatarData } from '../../../../util/format';
import { moneyMask } from '../../../../util/mask';

export interface TootipCardProps {
  order: IPedidosRotasResponse;
}

const TootipCard: FC<TootipCardProps> = ({ order }): JSX.Element => {
  return (
    <div className="p-0.5">
      {/* ID */}
      <p className="mb-1 text-xs ">
        <span className="font-bold ">Número CTE: </span>
        {order.pedido.nCT}
      </p>

      {/* Volumes */}
      <p className="text-xs ">
        <span className="font-semibold">Volumes: </span>
        {order.pedido.volumes}
      </p>

      {/* Value */}
      <p className="text-xs ">
        <span className="font-semibold">Valor: </span>
        {moneyMask(order.pedido.vlCarga, 'R$', 2)}
      </p>

      {/* Weight */}
      <p className="text-xs ">
        <span className="font-semibold">Peso taxado: </span>
        {moneyMask(order.pedido.pesoTaxado, '', 2)} kg
      </p>

      {/* Delivery date */}
      <p className="text-xs ">
        <span className="font-semibold">Entrega até: </span>
        {formatarData(order.pedido.dtLimiteEntrega, true, 'normal')}
      </p>
    </div>
  );
};

export default TootipCard;

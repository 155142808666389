import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError, CancelTokenSource } from 'axios';
import { RoutesAPI } from '../../../config/api';
import {
  IBadRequestResponse,
  IControleResponse,
} from '../../../interfaces/IBadRequestResponse';
import { IRotaASairRequestBody } from '../../../interfaces/request/Rotas/IRotaASairRequestBody';
import { IRotaFinalizarRequestBody } from '../../../interfaces/request/Rotas/IRotaFinalizarRequestBody';
import { IRota } from '../../../interfaces/response/Rotas/IRota';
import { IRotasResponse } from '../../../interfaces/response/Rotas/IRotasResponse';

export const getRoute = createAsyncThunk(
  'gerirRotas/getRoute',
  async (
    args: {
      filter: {
        idStatusRota: number;
        dataInicio: string;
        dataFim: string;
        filterType: '0' | '1';
      };
      cancelToken: CancelTokenSource;
    },
    { rejectWithValue }
  ): Promise<IRotasResponse | any> => {
    const { cancelToken, filter } = args;
    const { idStatusRota, dataFim, dataInicio, filterType } = filter;
    try {
      const { data } = await RoutesAPI.get<IRota[]>(
        `/api/v1/routes?Status=${idStatusRota}&DataInicio=${dataInicio}&DataFim=${dataFim}&filterType=${filterType}`,
        { cancelToken: cancelToken.token }
      );

      return data;
    } catch (err) {
      const error = err as AxiosError<IBadRequestResponse>;

      return rejectWithValue(error);
    }
  }
);

export const getRouteByID = createAsyncThunk(
  'gerirRotas/getRouteByID',
  async (
    args: {
      idRota: string;
      cancelToken: CancelTokenSource;
    },
    { rejectWithValue }
  ): Promise<IRotasResponse | any> => {
    const { cancelToken } = args;
    try {
      const { data } = await RoutesAPI.get<IRotasResponse>(
        `/api/v1/routes/id/${args.idRota}`,
        { cancelToken: cancelToken.token }
      );

      return data.rota;
    } catch (err) {
      const error = err as AxiosError<IBadRequestResponse>;

      return rejectWithValue(error);
    }
  }
);

export const postRouteToLeave = createAsyncThunk(
  'gerirRotas/postRouteToLeave',
  async (
    args: {
      body: IRotaASairRequestBody;
      cancelToken: CancelTokenSource;
    },
    { rejectWithValue }
  ): Promise<IControleResponse | any> => {
    const { body, cancelToken } = args;
    try {
      const { data } = await RoutesAPI.post<IControleResponse>(
        `api/v1/approval/leave`,
        body,
        { cancelToken: cancelToken.token }
      );

      return data;
    } catch (err) {
      const error = err as AxiosError<IBadRequestResponse>;

      return rejectWithValue(error);
    }
  }
);

export const postRouteFinalize = createAsyncThunk(
  'gerirRotas/postRouteFinalize',
  async (
    args: {
      body: IRotaFinalizarRequestBody;
      cancelToken: CancelTokenSource;
    },
    { rejectWithValue }
  ): Promise<IControleResponse | any> => {
    const { body, cancelToken } = args;
    try {
      const { data } = await RoutesAPI.post<IControleResponse>(
        `api/v1/approval/end`,
        body,
        { cancelToken: cancelToken.token }
      );

      return data;
    } catch (err) {
      const error = err as AxiosError<IBadRequestResponse>;

      return rejectWithValue(error);
    }
  }
);

export const postOrderEvent = createAsyncThunk(
  'gerirRotas/postOrderEvent',
  async (
    args: {
      body: {
        idRotaPedidos: string[];
        ocorrencia: string | undefined;
        finalizaPedido: boolean;
      };
      cancelToken: CancelTokenSource;
    },
    { rejectWithValue }
  ): Promise<IControleResponse | any> => {
    const { body, cancelToken } = args;
    try {
      const { data } = await RoutesAPI.post<IControleResponse>(
        `api/v1/order/event`,
        body,
        { cancelToken: cancelToken.token }
      );

      return data;
    } catch (err) {
      const error = err as AxiosError<IBadRequestResponse>;

      return rejectWithValue(error);
    }
  }
);

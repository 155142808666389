import React, { FC } from 'react';
import { Deposito } from '../../../../interfaces/IUsuario';
import { IRota } from '../../../../interfaces/response/Rotas/IRota';
import { OrderLocations } from '../OrderLocations/OrderLocations';
import useCreateMap from './hooks/useCreatemap';

export const CreateMap: FC<{
  warehouse: Deposito | undefined;
  rotas: IRota[];
  width?: string;
  height?: string;
  borderRadius?: string;
  showWaypoints?: boolean;
}> = ({
  warehouse,
  rotas,
  width,
  height,
  borderRadius,
  showWaypoints,
}): JSX.Element => {
  const { mapContainerRef, map } = useCreateMap();

  return (
    <>
      {/* Map Container */}
      <div
        ref={mapContainerRef}
        style={{
          height: height ?? 'calc(100vh - 65px)',
          width: width ?? '30%',
          borderRadius: borderRadius ?? '0px',
        }}
        className={width ?? 'w-1/2'}
      />

      {/* Location Markers */}
      {map && (
        <OrderLocations
          rotas={rotas}
          map={map}
          warehouse={warehouse}
          showWaypoints={showWaypoints}
        />
      )}
    </>
  );
};

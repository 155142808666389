import {
  Dispatch,
  RefObject,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import {
  Theme,
  ThemeContext,
  ThemeContextType,
} from '../../../contexts/ThemeContext/ThemeContext';
import { IOrderCheckResponse } from '../../../interfaces/response/Orders/IOrderCheckResponse';
import { IPrintOrderTagModalHandle } from '../components/PrintOrderTagModal/PrintOrderTagModal';

export interface UseCheckOrders {
  disableAutoSubmit: boolean;
  setDisableAutoSubmit: Dispatch<SetStateAction<boolean>>;
  submitToPrinter: (auto: boolean) => Promise<void>;
  openModalWithTag: (tag: IOrderCheckResponse) => void;
  printModalRef: RefObject<IPrintOrderTagModalHandle>;
  theme: Theme;
  setNfKey: Dispatch<SetStateAction<string>>;
  navigate: NavigateFunction;
  nfKey: string;
  addPrintedOrder: (printedOrder: IOrderCheckResponse) => void;
  printedOrdersArray: IOrderCheckResponse[];
}

const useCheckOrders = (): UseCheckOrders => {
  const [disableAutoSubmit, setDisableAutoSubmit] = useState<boolean>(false);
  const [nfKey, setNfKey] = useState<string>('');
  const printModalRef = useRef<IPrintOrderTagModalHandle>(null);
  const { theme } = useContext<ThemeContextType>(ThemeContext);
  const navigate = useNavigate();
  const [printedOrdersArray, setPrintedOrdersArray] = useState<
    IOrderCheckResponse[]
  >([]);

  const addPrintedOrder = (printedOrder: IOrderCheckResponse): void => {
    setNfKey('');
    setPrintedOrdersArray((array) => [printedOrder, ...array]);
  };

  const submitToPrinter = async (auto: boolean): Promise<void> => {
    printModalRef.current?.openModalToPrint(nfKey, auto);
  };

  const openModalWithTag = (tag: IOrderCheckResponse): void => {
    printModalRef.current?.openModal(tag);
  };

  // Validate auto-submit
  useEffect(() => {
    if (nfKey.length === 44 && !disableAutoSubmit) {
      submitToPrinter(true);
    }
  }, [nfKey]);

  return {
    disableAutoSubmit,
    setDisableAutoSubmit,
    theme,
    navigate,
    setNfKey,
    submitToPrinter,
    printModalRef,
    nfKey,
    addPrintedOrder,
    printedOrdersArray,
    openModalWithTag,
  };
};

export default useCheckOrders;

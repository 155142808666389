import { Switch, SwitchThumbIconProps } from '@nextui-org/react';
import React, { useContext } from 'react';
import { FaMoon, FaSun } from 'react-icons/fa';
import {
  ThemeContext,
  ThemeContextType,
} from '../../contexts/ThemeContext/ThemeContext';

const ThemeSwitch = (): JSX.Element => {
  const { theme, toggleTheme } = useContext<ThemeContextType>(ThemeContext);

  const getIcon = ({
    isSelected,
    className,
  }: SwitchThumbIconProps): JSX.Element => {
    if (isSelected) return <FaMoon className={className} />;
    return <FaSun className={className} />;
  };

  return (
    <Switch
      isSelected={theme === 'dark'}
      onClick={toggleTheme}
      size="md"
      color="default"
      thumbIcon={getIcon}
    />
  );
};

export default ThemeSwitch;

/* eslint-disable react/no-array-index-key */
import { Input, Spinner } from '@nextui-org/react';
import React from 'react';
import RegisterVehicleModal from '../../../components/RegisterVehicleModal/RegisterVehicleModal';
import VehicleTable from '../components/VehicleTable/VehicleTable';
import useVeiculo from './hooks/useVeiculos';

const Veiculo = (): JSX.Element => {
  const {
    vehicles,
    setFilter,
    loading,
    modalRef,
    vehicleTypeOptions,
    handleGetVehicles,
  } = useVeiculo();

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-row items-end justify-between">
        <p className="text-default-500">
          Selecione um veiculo para ver seus detalhes ou edita-lo:
        </p>

        <div className="grid grid-cols-2 items-end gap-8 ">
          <Input
            size="md"
            type="text"
            label="Filtro"
            variant="underlined"
            placeholder="Placa"
            autoFocus
            classNames={{
              label: 'font-bold',
            }}
            onValueChange={(value) => {
              setFilter(value);
            }}
          />

          <RegisterVehicleModal ref={modalRef} onUpdate={handleGetVehicles} />
        </div>
      </div>

      {loading && <Spinner label="Baixando conteúdo" color="default" />}

      {!loading && (
        <VehicleTable
          vehicleTypeOptions={vehicleTypeOptions}
          vehicles={vehicles}
          onRowClick={(vehicle) => modalRef.current?.openToEdit(vehicle)}
        />
      )}
    </div>
  );
};

export default Veiculo;

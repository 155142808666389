import { Select, SelectItem } from '@nextui-org/react';
import React, { FC } from 'react';
import { ConfirmationModalHandle } from '../ConfirmationModal/ConfirmationModal';
import useWarehouseDropdown from './hooks/useWarehouseDropdown';

export interface WarehouseProps {
  confirmModalRef: React.RefObject<ConfirmationModalHandle>;
}

const WarehouseDropdown: FC<WarehouseProps> = ({
  confirmModalRef,
}: WarehouseProps): JSX.Element => {
  const { isLoading, onChange, selectedWarehouse, theme, warehouseOptions } =
    useWarehouseDropdown({ confirmModalRef });

  return (
    <Select
      aria-label="Seletor de depósitos"
      variant="flat"
      size="sm"
      classNames={{
        base: 'max-w-[400px]',
        value: 'font-semibold',
        popoverContent: `${theme}`,
      }}
      selectedKeys={selectedWarehouse}
      className={`${theme}`}
      onSelectionChange={onChange}
      isLoading={isLoading}
      isDisabled={isLoading}
    >
      {warehouseOptions.map(({ idDeposito, descricao }) => (
        <SelectItem
          key={idDeposito}
          value={idDeposito}
          textValue={descricao.toUpperCase()}
        >
          <p className="font-medium text-foreground-700">
            {descricao.toUpperCase()}
          </p>
        </SelectItem>
      ))}
    </Select>
  );
};

export default WarehouseDropdown;

/* eslint-disable react/no-array-index-key */
import { Input, Spinner } from '@nextui-org/react';
import React from 'react';
import RegisterLocationPriceModal from '../../../components/RegisterLocationPriceModal/RegisterLocationPriceModal';
import LocationPriceTable from '../components/LocationPriceTable/LocationPriceTable';
import useTabelaPreco from './hooks/useTabelaPreco';

const TabelaPreco = (): JSX.Element => {
  const {
    handleGetLocationsPrice,
    loading,
    locationsPrices,
    modalRef,
    setFilter,
    vehicleTypeOptions,
  } = useTabelaPreco();

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-row items-end justify-between">
        <p className="text-default-500">
          Selecione uma tabela de preço para ver seus detalhes ou edita-la:
        </p>

        <div className="grid grid-cols-2 items-end gap-8 ">
          <Input
            size="md"
            type="text"
            label="Filtro"
            variant="underlined"
            placeholder="CEP Início"
            autoFocus
            classNames={{
              label: 'font-bold',
            }}
            onValueChange={(value) => {
              setFilter(value);
            }}
          />

          <RegisterLocationPriceModal
            onUpdate={handleGetLocationsPrice}
            ref={modalRef}
          />
        </div>
      </div>

      {loading && <Spinner label="Baixando conteúdo" color="default" />}

      {!loading && (
        <LocationPriceTable
          locationsPrices={locationsPrices}
          onRowClick={(locationPrice) =>
            modalRef.current?.openToEdit(locationPrice)
          }
          vehicleTypeOptions={vehicleTypeOptions}
        />
      )}
    </div>
  );
};

export default TabelaPreco;

import { useDisclosure } from '@nextui-org/react';
import { useContext } from 'react';
import {
  Theme,
  ThemeContext,
  ThemeContextType,
} from '../../../../../../contexts/ThemeContext/ThemeContext';

export interface UseModalDetalhePedido {
  isOpen: boolean;
  onOpen: () => void;
  handleClose: () => void;
  theme: Theme;
}

const useModalDetalhePedido = (): UseModalDetalhePedido => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { theme } = useContext<ThemeContextType>(ThemeContext);

  const handleClose = (): void => {
    onClose();
  };

  return {
    handleClose,
    isOpen,
    onOpen,
    theme,
  };
};

export default useModalDetalhePedido;

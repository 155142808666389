import { SortDescriptor } from '@nextui-org/react';
import {
  Dispatch,
  Key,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { ITipoVeiculo } from '../../../../../interfaces/response/TipoVeiculo/ITipoVeiculo';
import { IVeiculo } from '../../../../../interfaces/response/Veiculo/IVeiculo';
import { formatarData } from '../../../../../util/format';
import { moneyMask } from '../../../../../util/mask';

export type Header =
  | 'idVeiculo'
  | 'placa'
  | 'idTipoVeiculo'
  | 'dataUltimoLicenciamento'
  | 'dataPesquisa'
  | 'numeroANTT'
  | 'ativo'
  | 'cubagem_Diametro'
  | 'cubagem_Largura'
  | 'cubagem_Altura'
  | 'peso'
  | 'valor'
  | 'dataCadastro';

type Column = {
  key: Header;
  name: string;
};

export interface UseVehicleTableProps {
  vehicles: IVeiculo[];
  onRowClick?: (vehicle: IVeiculo) => void;
  vehicleTypeOptions: ITipoVeiculo[];
}

export interface UseVehicleTable {
  handleRowClick: (key: Key) => void;
  sortDescriptor: SortDescriptor;
  setSortDescriptor: Dispatch<SetStateAction<SortDescriptor>>;
  columns: Column[];
  sortedVehicles: IVeiculo[];
  cellData: (vehicle: IVeiculo, columnKey: Header) => number | string;
}

const useVehicleTable = ({
  vehicles,
  onRowClick,
  vehicleTypeOptions,
}: UseVehicleTableProps): UseVehicleTable => {
  const [sortDescriptor, setSortDescriptor] = useState<SortDescriptor>({
    column: 'idVeiculo',
    direction: 'descending',
  });

  const sortedVehicles = useMemo(() => {
    return [...vehicles].sort((a: IVeiculo, b: IVeiculo) => {
      const first = a[sortDescriptor.column as keyof IVeiculo] as number;
      const second = b[sortDescriptor.column as keyof IVeiculo] as number;

      let cmp = 0;
      if (first < second) cmp = -1;
      if (first > second) cmp = 1;

      return sortDescriptor.direction === 'ascending' ? -cmp : cmp;
    });
  }, [sortDescriptor, vehicles]);

  const columns: Column[] = [
    {
      key: 'idVeiculo',
      name: 'ID',
    },
    {
      key: 'placa',
      name: 'PLACA',
    },
    {
      key: 'idTipoVeiculo',
      name: 'CATEGORIA',
    },
    {
      key: 'dataUltimoLicenciamento',
      name: 'DATA DE LICENCIAMENTO',
    },
    {
      key: 'dataPesquisa',
      name: 'DATA DE PESQUISA',
    },
    {
      key: 'numeroANTT',
      name: 'ANTT',
    },
    {
      key: 'ativo',
      name: 'ATIVO',
    },
    {
      key: 'cubagem_Diametro',
      name: 'DIÂMETRO',
    },
    {
      key: 'cubagem_Largura',
      name: 'LARGURA',
    },
    {
      key: 'cubagem_Altura',
      name: 'ALTURA',
    },
    {
      key: 'peso',
      name: 'PESO',
    },
    {
      key: 'valor',
      name: 'VALOR PGR',
    },
    {
      key: 'dataCadastro',
      name: 'DATA CADASTRO',
    },
  ];

  const cellData = useCallback(
    (vehicle: IVeiculo, columnKey: Header): number | string => {
      switch (columnKey) {
        case 'idVeiculo':
          return vehicle.idVeiculo;
        case 'placa':
          return vehicle.placa;
        case 'idTipoVeiculo':
          return (
            vehicleTypeOptions.find(
              (vehicleAux) => vehicleAux.idTipoVeiculo === vehicle.idTipoVeiculo
            )?.categoria ?? vehicle.idTipoVeiculo
          );
        case 'dataUltimoLicenciamento':
          return formatarData(vehicle.dataUltimoLicenciamento) ?? '';
        case 'dataPesquisa':
          return formatarData(vehicle.dataPesquisa) ?? '';
        case 'numeroANTT':
          return vehicle.numeroANTT;
        case 'ativo':
          return vehicle.ativo ? 'SIM' : 'NÃO';
        case 'cubagem_Diametro':
          return `${moneyMask(vehicle.cubagem_Diametro, '', 2)} m`;
        case 'cubagem_Largura':
          return `${moneyMask(vehicle.cubagem_Largura, '', 2)} m`;
        case 'cubagem_Altura':
          return `${moneyMask(vehicle.cubagem_Altura, '', 2)} m`;
        case 'peso':
          return `${moneyMask(vehicle.peso, '', 2)} kg`;
        case 'valor':
          return moneyMask(vehicle.valor, 'R$', 2);
        case 'dataCadastro':
          return formatarData(vehicle.dataCadastro) ?? '';
        default:
          return '';
      }
    },
    [vehicleTypeOptions]
  );

  const handleRowClick = (key: Key): void => {
    const vehicle = vehicles.find(
      (vehicleAux) => vehicleAux.idVeiculo.toString() === key
    );
    if (onRowClick && vehicle) onRowClick(vehicle);
  };

  return {
    cellData,
    columns,
    handleRowClick,
    setSortDescriptor,
    sortDescriptor,
    sortedVehicles,
  };
};

export default useVehicleTable;

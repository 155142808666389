/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable consistent-return */
/* eslint-disable import/no-unresolved */
import React, { FC, useEffect, useRef } from 'react';
import { createRoot, Root } from 'react-dom/client';

export interface MarkerProps {
  map: google.maps.Map;
  children: React.ReactElement;
  position: {
    lat: number;
    lng: number;
  };
  onClick?: () => void;
}

const Marker: FC<MarkerProps> = ({
  children,
  map,
  position,
  onClick = () => {},
}): null => {
  const markerRef = useRef<google.maps.marker.AdvancedMarkerElement>();
  const rootRef = useRef<Root>();

  useEffect(() => {
    if (rootRef.current) return;

    const markerContainer = document.createElement('div');
    markerContainer.setAttribute('id', 'rendered');
    rootRef.current = createRoot(markerContainer);

    markerRef.current = new google.maps.marker.AdvancedMarkerElement({
      position,
      content: markerContainer,
    });
  }, []);

  useEffect((): undefined | (() => void) => {
    rootRef.current?.render(children);
    if (!markerRef.current) return;

    markerRef.current.position = position;
    const listener = markerRef.current.addListener('gmp-click', onClick);
    markerRef.current.map = map;

    return () => {
      listener.remove();
    };
  }, [map, position, children]);

  return null;
};

export default Marker;

import { Button } from '@nextui-org/react';
import React, { FC, useState } from 'react';
import { AiOutlineMenuFold, AiOutlineMenuUnfold } from 'react-icons/ai';

interface ToggleMenuButtonProps {
  onMenuToggle: (isOpen: boolean) => void;
}

const ToggleMenuButton: FC<ToggleMenuButtonProps> = ({
  onMenuToggle,
}): JSX.Element => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const handleMenuToggle = (): void => {
    onMenuToggle(isMenuOpen);
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div>
      <Button
        onPress={handleMenuToggle}
        isIconOnly
        aria-label="Toggle menu"
        variant={isMenuOpen ? 'solid' : 'ghost'}
        className="border-none"
      >
        {isMenuOpen ? (
          <AiOutlineMenuFold className="h-6 w-6" />
        ) : (
          <AiOutlineMenuUnfold className="h-6 w-6" />
        )}
      </Button>
    </div>
  );
};

export default ToggleMenuButton;

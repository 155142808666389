/* eslint-disable import/no-unresolved */
import { NextUIProvider } from '@nextui-org/react';
import React from 'react';
import 'react-datepicker/src/stylesheets/datepicker.scss';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import persistStore from 'redux-persist/es/persistStore';
import { PersistGate } from 'redux-persist/integration/react';
import App from './App';
import { ThemeProvider } from './contexts/ThemeContext/ThemeContext';
import './output.css';
import { store } from './redux/store';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const persistor = persistStore(store);

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <NextUIProvider>
        <ThemeProvider>
          <App />
        </ThemeProvider>
      </NextUIProvider>
    </PersistGate>
  </Provider>
);

/* eslint-disable react-hooks/exhaustive-deps */
import { zodResolver } from '@hookform/resolvers/zod';
import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { Control, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { z } from 'zod';
import useTimeout from '../../../../hooks/useTimeout';
import { IConsultaPlanilhasResponse } from '../../../../interfaces/response/Planilha/IConsultaPlanilhasResponse';
import { IPlanilha } from '../../../../interfaces/response/Planilha/IPlanilha';
import { ObterPlanilhas } from '../../../../redux/features/clientsData/clientsDataThunk';
import { selectOrdersDate } from '../../../../redux/features/generalData/generalDataSelectors';
import { setOrdersDate } from '../../../../redux/features/generalData/generalDataSlice';
import { useAsyncDispatch } from '../../../../redux/store';
import { formatarData } from '../../../../util/format';

interface IProps {
  loading: boolean;
  planilhas: IPlanilha[];
  control: Control<FormSchema>;
  startDate: Date | undefined;
  endDate: Date | undefined;
  consultarMotoristas: () => Promise<void>;
}

export interface IMessageProps {
  mensagem: string;
  show: boolean;
  type: 'success' | 'error';
}

const formSchema = z.object({
  startDate: z.date().optional(),
  endDate: z.date().optional(),
});

type FormSchema = z.infer<typeof formSchema>;

const usePlanilhas = (): IProps => {
  const dispatch = useAsyncDispatch();
  const [planilhas, setPlanilhas] = useState<IPlanilha[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const { startOrdersDate, endOrdersDate } = useSelector(selectOrdersDate);
  const { control, watch } = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      endDate: new Date(endOrdersDate),
      startDate: new Date(startOrdersDate),
    },
  });
  const { startDate, endDate } = watch();

  const consultarMotoristas = async (): Promise<void> => {
    setLoading(true);

    if (startDate && endDate)
      dispatch(
        setOrdersDate({
          startOrdersDate: startDate,
          endOrdersDate: endDate,
          searchType: '0',
        })
      );

    await dispatch(
      ObterPlanilhas({
        dataInicio: formatarData(startDate, true, 'input') ?? '',
        dataFim: formatarData(endDate, true, 'input') ?? '',
      })
    )
      .unwrap()
      .then((response) => {
        const planilhasResponse: IConsultaPlanilhasResponse = response;
        setPlanilhas(
          planilhasResponse.planilhas.sort((a, b) => {
            if (a.indProcessada && b.indProcessada) return 0;
            // a not processed
            if (!a.indProcessada) return 1;
            // b not processed
            return -1;
          })
        );

        const processingSpreadsheet =
          planilhasResponse.planilhas.find((sheet) => sheet.indProcessada) ===
          undefined;
        setIsProcessing(processingSpreadsheet);
      })
      .catch((error) => {
        const axiosError = error as AxiosError;
        if (axiosError.code === 'ERR_CANCELED') return;

        toast.error('Ocorreu um erro ao consultar os pedidos');
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    consultarMotoristas();
  }, [startDate, endDate]);

  // 20s recalling when theres a processing
  useTimeout(() => consultarMotoristas(), 30 * 1000, isProcessing);

  return {
    planilhas,
    loading,
    control,
    endDate,
    startDate,
    consultarMotoristas,
  };
};

export default usePlanilhas;

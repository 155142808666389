import axios, { AxiosError, CancelTokenSource } from 'axios';
import { Dispatch, SetStateAction, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { CadastraCTE } from '../../../../../../redux/features/clientsData/clientsDataThunk';
import { useAsyncDispatch } from '../../../../../../redux/store';

export interface UseDragDrop {
  handleChange: (fileAux: File) => void;
  fileTypes: string[];
  file: File | null;
  setFile: Dispatch<SetStateAction<File | null>>;
  handleSend: () => Promise<void>;
  loading: boolean;
}

interface IDragDropProps {
  callback?: () => Promise<void>;
}

const useDragDrop = ({ callback }: IDragDropProps): UseDragDrop => {
  const cancelTokenRef = useRef<CancelTokenSource>();
  const cancelToken = axios.CancelToken;
  const [file, setFile] = useState<File | null>(null);
  const dispatch = useAsyncDispatch();
  const fileTypes = ['XML'];
  const [loading, setLoading] = useState<boolean>(false);

  const handleChange = (fileAux: File): void => {
    setFile(fileAux);
  };

  const handleSend = async (): Promise<void> => {
    if (!file) return;

    const source: CancelTokenSource = cancelToken.source();
    cancelTokenRef.current = source;
    setLoading(true);
    await dispatch(
      CadastraCTE({
        file,
      })
    )
      .unwrap()
      .then(() => {
        setFile(null);
        if (callback) callback();
      })
      .catch((error) => {
        const axiosError = error as AxiosError;
        setLoading(false);

        if (axiosError.code === 'ERR_CANCELED') return;

        toast.error(
          error?.response?.data?.message || 'Erro ao realizar upload do arquivo'
        );
      });
    setLoading(false);
  };

  return {
    file,
    fileTypes,
    handleChange,
    handleSend,
    setFile,
    loading,
  };
};

export default useDragDrop;

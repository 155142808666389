import {
  Button,
  Card,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  useDisclosure,
} from '@nextui-org/react';
import React, {
  forwardRef,
  ForwardRefRenderFunction,
  useContext,
  useImperativeHandle,
  useState,
} from 'react';
import {
  ThemeContext,
  ThemeContextType,
} from '../../../../../../contexts/ThemeContext/ThemeContext';
import { RouteError } from '../../../../hooks/useCreateRoutes';

export interface ConfirmationModalHandle {
  confirmAction: () => Promise<void>;
}

interface ConfirmationModalProps {
  routeErrors: Set<RouteError>;
}

const ConfirmationModal: ForwardRefRenderFunction<
  ConfirmationModalHandle,
  ConfirmationModalProps
> = ({ routeErrors }, ref): JSX.Element => {
  const { theme } = useContext<ThemeContextType>(ThemeContext);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [resolveAction, setResolveAction] = useState<(() => void) | null>(null);
  const [rejectAction, setRejectAction] = useState<(() => void) | null>(null);

  const handleResolveAction = (): void => {
    if (!resolveAction) return;
    resolveAction();
  };

  const handleRejectAction = (): void => {
    if (!rejectAction) return;
    rejectAction();
  };

  const handleOpen = (): Promise<void> => {
    const modalPromise = new Promise<void>((resolve, reject) => {
      const resolveFn = () => (): void => {
        onClose();
        resolve();
      };
      const rejectFn = () => (): void => {
        onClose();
        reject(new Error('User cancelled'));
      };

      setResolveAction(resolveFn);
      setRejectAction(rejectFn);
    });

    onOpen();
    return modalPromise;
  };

  const handleClose = (): void => {
    handleRejectAction();
    onClose();
  };

  useImperativeHandle(ref, () => ({
    confirmAction: () => {
      return handleOpen();
    },
  }));

  return (
    <Modal isOpen={isOpen} className={theme} onClose={handleClose} size="2xl">
      <ModalContent>
        <ModalHeader className="text-default-600">Rota com alertas</ModalHeader>

        <ModalBody className="gap-4">
          <p className="text-sm text-default-500">
            Aprovar a rota com os seguintes alertas?
          </p>

          <Card
            className="gap-1 border border-foreground-200 p-4"
            shadow="none"
          >
            {Array.from(routeErrors).map((routeError) => (
              <p
                key={routeError.errorCode}
                className="whitespace-nowrap text-base font-medium text-foreground-600"
              >
                {routeError.message}
              </p>
            ))}
          </Card>
        </ModalBody>

        <ModalFooter>
          <Button color="danger" onPress={handleRejectAction}>
            Cancelar
          </Button>

          <Button onPress={handleResolveAction}>Criar Rota</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default forwardRef(ConfirmationModal);

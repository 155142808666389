import { combineReducers } from '@reduxjs/toolkit';
import clientsDataSlice from './features/clientsData/clientsDataSlice';
import { ClientsDataState } from './features/clientsData/clientsDataTypes';
import generalDataSlice from './features/generalData/generalDataSlice';
import { GeneralDataState } from './features/generalData/generalDataTypes';

export interface AppState {
  generalData: GeneralDataState;
  clientsData: ClientsDataState;
}

export const rootReducer = combineReducers<AppState>({
  generalData: generalDataSlice.reducer,
  clientsData: clientsDataSlice.reducer,
});

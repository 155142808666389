import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

export type Theme = 'dark' | 'light';

export interface ThemeContextType {
  toggleTheme: () => void;
  theme: Theme;
}

export const ThemeContext = createContext<ThemeContextType>(
  {} as ThemeContextType
);

type ThemeProviderProps = {
  children?: JSX.Element;
};

export const ThemeProvider: React.FunctionComponent<ThemeProviderProps> = ({
  children,
}): JSX.Element => {
  const [theme, setTheme] = useState<Theme>('light');

  const getThemeFromLocalStorage = (): void => {
    const localStorageTheme = localStorage.getItem('theme');

    if (localStorageTheme) setTheme(localStorageTheme as Theme);
  };

  useEffect(() => {
    getThemeFromLocalStorage();
  }, []);

  const toggleTheme = (): void => {
    if (theme === 'light') {
      localStorage.setItem('theme', 'dark');
      setTheme('dark');
    } else {
      localStorage.setItem('theme', 'light');
      setTheme('light');
    }
  };

  const providerProps = useMemo<ThemeContextType>(() => {
    return { theme, toggleTheme };
  }, [theme]);

  return (
    <ThemeContext.Provider value={providerProps}>
      <div className={theme}>{children}</div>
    </ThemeContext.Provider>
  );
};

export const useThemeContext = (): ThemeContextType => useContext(ThemeContext);

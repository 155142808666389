import { Snippet } from '@nextui-org/react';
import React from 'react';
import { formatarData } from '../../../../../../../util/format';

interface ITabProps {
  erro: {
    indCriticidade: number;
    mensagemErro: string;
    dataErro: Date;
    short?: boolean;
  };
}

const TabErros = ({ erro }: ITabProps): JSX.Element => {
  const getSnnipetColor = (
    criticidade: number
  ): 'default' | 'primary' | 'secondary' | 'success' | 'warning' | 'danger' => {
    switch (criticidade) {
      case 1:
        return 'warning';
      case 2:
        return 'danger';
      default:
        return 'default';
    }
  };

  return (
    <Snippet
      style={{
        width: '100%',
      }}
      size="lg"
      hideSymbol
      color={getSnnipetColor(erro.indCriticidade)}
      hideCopyButton
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        {erro.short ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <div>{erro.mensagemErro}</div>
          </div>
        ) : (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '93%',

              minWidth: '900px',
            }}
          >
            <div>{erro.mensagemErro}</div>
            <div>{formatarData(erro.dataErro, false, 'normal')}</div>
          </div>
        )}
      </div>
    </Snippet>
  );
};

export default TabErros;

/* eslint-disable consistent-return */
import { useEffect, useRef } from 'react';

function useTimeout(
  callback: () => void,
  interval: number,
  enabled: boolean
): void {
  const savedCallback = useRef<() => void>();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    if (!enabled) return;

    const handler = setInterval(() => {
      savedCallback.current?.();
    }, interval);

    // Clear interval if the component is unmounted or the interval changes.
    return () => clearInterval(handler);
  }, [interval, enabled]);
}

export default useTimeout;

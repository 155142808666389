import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  useDisclosure,
} from '@nextui-org/react';
import React, {
  forwardRef,
  ForwardRefRenderFunction,
  useContext,
  useImperativeHandle,
  useState,
} from 'react';
import {
  ThemeContext,
  ThemeContextType,
} from '../../contexts/ThemeContext/ThemeContext';
import { RouteError } from '../../pages/CreateRoute/hooks/useCreateRoutes';

export interface ConfirmationModalHandle {
  confirmAction: () => Promise<void>;
}

interface ConfirmationModalProps {
  routeErrors?: Set<RouteError>;
  header: string;
  text: string;
  cancelText: string;
  confirmText: string;
}

const ConfirmationModal: ForwardRefRenderFunction<
  ConfirmationModalHandle,
  ConfirmationModalProps
> = (
  { routeErrors, cancelText, confirmText, header, text },
  ref
): JSX.Element => {
  const { theme } = useContext<ThemeContextType>(ThemeContext);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [resolveAction, setResolveAction] = useState<(() => void) | null>(null);
  const [rejectAction, setRejectAction] = useState<(() => void) | null>(null);

  const handleResolveAction = (): void => {
    if (!resolveAction) return;
    resolveAction();
  };

  const handleRejectAction = (): void => {
    if (!rejectAction) return;
    rejectAction();
  };

  const handleOpen = (): Promise<void> => {
    const modalPromise = new Promise<void>((resolve, reject) => {
      const resolveFn = () => (): void => {
        onClose();
        resolve();
      };
      const rejectFn = () => (): void => {
        onClose();
        reject(new Error('User cancelled'));
      };

      setResolveAction(resolveFn);
      setRejectAction(rejectFn);
    });

    onOpen();
    return modalPromise;
  };

  const handleClose = (): void => {
    handleRejectAction();
    onClose();
  };

  useImperativeHandle(ref, () => ({
    confirmAction: () => {
      return handleOpen();
    },
  }));

  return (
    <Modal isOpen={isOpen} className={theme} onClose={handleClose} size="2xl">
      <ModalContent>
        <ModalHeader className="text-default-600">{header}</ModalHeader>

        <ModalBody className="gap-4">
          <p className="text-sm text-default-500">{text}</p>
        </ModalBody>

        <ModalFooter>
          <Button color="danger" onPress={handleResolveAction}>
            {confirmText}
          </Button>

          <Button onPress={handleRejectAction}>{cancelText}</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default forwardRef(ConfirmationModal);

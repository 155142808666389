import { zodResolver } from '@hookform/resolvers/zod';
import React, { useState } from 'react';
import {
  FieldErrors,
  useForm,
  UseFormHandleSubmit,
  UseFormRegister,
} from 'react-hook-form';
import toast from 'react-hot-toast';
import { z } from 'zod';
import { User } from '../../../interfaces/IUsuario';
import { logIn } from '../../../redux/features/generalData/generalDataSlice';
import { postLogin } from '../../../redux/features/generalData/generalDataThunk';
import { useAsyncDispatch } from '../../../redux/store';

const loginFormSchema = z.object({
  email: z.string().min(1, 'E-mail é obrigatório').email('E-mail inválido'),
  password: z
    .string()
    .min(1, 'Senha é obrigatória')
    .min(8, 'Senha deve ter no mínimo 8 caractéres'),
});

type LoginForm = z.infer<typeof loginFormSchema>;
export interface IUseLogin {
  handleSubmit: UseFormHandleSubmit<LoginForm>;
  handleLogin: (formData: LoginForm) => Promise<void>;
  errors: FieldErrors<LoginForm>;
  register: UseFormRegister<LoginForm>;
  isVisible: boolean;
  toggleVisibility: () => void;
  isLoading: boolean;
}
export interface IProps {
  changeLayout: () => void;
}

const useLogin = ({ changeLayout }: IProps): IUseLogin => {
  const dispatch = useAsyncDispatch();
  const [isVisible, setIsVisible] = React.useState(false);
  const toggleVisibility = (): void => setIsVisible(!isVisible);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginForm>({
    resolver: zodResolver(loginFormSchema),
    mode: 'onBlur',
  });

  const handleLogin = async ({ email, password }: LoginForm): Promise<void> => {
    setIsLoading(true);
    await dispatch(postLogin({ email, password }))
      .unwrap()
      .then((user: User) => {
        // TODO: Create user new password flux
        if (user.email === 'Exemplo de criar nova senha') changeLayout();

        dispatch(logIn());
      })
      .catch(() => {
        toast.error(
          'Ocorreu um erro ao realizar o Login, por favor tente mais tarde.'
        );
      })
      .finally(() => setIsLoading(false));
  };

  return {
    handleSubmit,
    handleLogin,
    errors,
    register,
    isVisible,
    toggleVisibility,
    isLoading,
  };
};

export default useLogin;

import { zodResolver } from '@hookform/resolvers/zod';
import { useDisclosure } from '@nextui-org/react';
import { useContext, useState } from 'react';
import {
  Control,
  FieldErrors,
  useForm,
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { z } from 'zod';
import {
  Theme,
  ThemeContext,
} from '../../../contexts/ThemeContext/ThemeContext';
import { ICadastraMotoristaRequest } from '../../../interfaces/request/Motorista/ICadastraMotoristaRequest';
import { IEditaMotoristaRequest } from '../../../interfaces/request/Motorista/IEditaMotoristaRequest';
import { IMotorista } from '../../../interfaces/response/Motorista/IMotorista';
import {
  cadastrarMotorista,
  editarMotorista,
} from '../../../redux/features/clientsData/clientsDataThunk';
import { selectUsuario } from '../../../redux/features/generalData/generalDataSelectors';
import { useAsyncDispatch } from '../../../redux/store';
import { cellphoneMask, cpfMask, validarCPF } from '../../../util/mask';

export enum DriverType {
  frota = 'frota',
  agregado = 'agregado',
}

const formSchema = z
  .object({
    driverType: z.enum([DriverType.frota, DriverType.agregado]),
    name: z.string().min(1, 'Nome é obrigatório'),
    email: z.string().min(1, 'E-mail é obrigatório').email('E-mail inválido'),
    cpf: z
      .string()
      .min(1, 'CPF é obrigatório')
      .transform((cpf) => cpf.replace(/\D+/g, ''))
      .refine((cpf) => validarCPF(cpf), 'CPf inválido'),
    phone: z
      .string()
      .min(1, 'Telefone é obrigatório')
      .transform((cpf) => cpf.replace(/\D+/g, '')),
    cnh: z.string().min(11, 'CNH deve ter 11 dígitos').length(11),
    maxOrders: z.string().min(1, 'Valor mínimo é 0'),
    cnhDueDate: z
      .date({ invalid_type_error: 'Data de vencimento é necessária' })
      .min(new Date(), 'Data de vencimento deve ser maior que a atual'),
    researcDate: z
      .date({ invalid_type_error: 'Data de pesquisa é necessária' })
      .min(new Date(), 'Data de pesquisa deve ser maior que a atual'),
    address: z
      .object({
        street: z.string().optional(),
        number: z.string().optional(),
        neighborhood: z.string().optional(),
        city: z.string().optional(),
        complement: z.string().optional(),
      })
      .optional(),
  })
  .refine(
    ({ driverType, address }) => {
      if (driverType === DriverType.frota) return true;
      if (address?.street === '') return false;
      return true;
    },
    {
      message: 'Rua é obrigatória',
      path: ['address.street'],
    }
  )
  .refine(
    ({ driverType, address }) => {
      if (driverType === DriverType.frota) return true;
      if (address?.number === '') return false;
      return true;
    },
    {
      message: 'Número é obrigatório',
      path: ['address.number'],
    }
  )
  .refine(
    ({ driverType, address }) => {
      if (driverType === DriverType.frota) return true;
      if (address?.neighborhood === '') return false;
      return true;
    },
    {
      message: 'Bairro é obrigatório',
      path: ['address.neighborhood'],
    }
  )
  .refine(
    ({ driverType, address }) => {
      if (driverType === DriverType.frota) return true;
      if (address?.city === '') return false;
      return true;
    },
    {
      message: 'Cidade é obrigatória',
      path: ['address.city'],
    }
  );

type FormSchema = z.infer<typeof formSchema>;

interface UseRegisterDriverModalProps {
  driver?: IMotorista;
  onUpdate?: () => void;
}

export interface UseRegisterDriverModal {
  errors: FieldErrors<FormSchema>;
  handleSubmitDriver: (data: FormSchema) => Promise<void>;
  handleClose: () => void;
  handleSubmit: UseFormHandleSubmit<FormSchema>;
  isLoading: boolean;
  isOpen: boolean;
  isValid: boolean;
  onOpen: () => void;
  onOpenChange: () => void;
  register: UseFormRegister<FormSchema>;
  theme: Theme;
  control: Control<FormSchema>;
  setValue: UseFormSetValue<FormSchema>;
  values: FormSchema;
}

const useRegisterDriverModal = ({
  onUpdate,
  driver,
}: UseRegisterDriverModalProps): UseRegisterDriverModal => {
  const dispatch = useAsyncDispatch();
  const { isOpen, onOpen, onClose, onOpenChange } = useDisclosure();
  const { theme } = useContext(ThemeContext);
  const user = useSelector(selectUsuario);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const defaultValues: FormSchema = {
    driverType:
      driver?.idTipoMotorista === 2 ? DriverType.agregado : DriverType.frota,
    name: driver?.nome ?? '',
    email: driver?.email ?? '',
    cpf: (driver && cpfMask(driver.cpf)) ?? '',
    phone: (driver && cellphoneMask(driver.telefone)) ?? '',
    cnh: driver?.nrCNH ?? '',
    maxOrders: driver?.qtdPedidosMaxima.toString() ?? '0',
    cnhDueDate: driver?.dataVencimentoCNH
      ? new Date(driver.dataVencimentoCNH)
      : new Date(),
    researcDate: driver?.dataVencimentoPesquisa
      ? new Date(driver.dataVencimentoPesquisa)
      : new Date(),
    address: {
      street: driver?.endereco ?? '',
      neighborhood: driver?.bairro ?? '',
      number: driver?.numero ?? '',
      city: driver?.cidade ?? '',
      complement: driver?.complemento ?? '',
    },
  };

  const {
    register,
    handleSubmit,
    clearErrors,
    formState,
    reset,
    control,
    setValue,
    watch,
  } = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
    defaultValues,
    mode: 'all',
    reValidateMode: 'onChange',
    resetOptions: {
      keepDefaultValues: true,
    },
  });
  const { errors, isValid } = formState;
  const values = watch();

  const handleClose = (): void => {
    onClose();
    clearErrors();
    reset();
  };

  const handleCreateDriver = async (data: FormSchema): Promise<void> => {
    if (!user) return;

    const { _Id: userId } = user;
    const {
      address,
      cnh,
      cnhDueDate,
      cpf: cpfValue,
      driverType: dataTypeValue,
      email,
      name,
      phone: phoneValue,
      researcDate,
      maxOrders,
    } = data;

    const body: ICadastraMotoristaRequest = {
      idDeposito: user.defaultWarehouse.idDeposito,
      nome: name,
      email,
      cPF: cpfValue,
      telefone: phoneValue,
      nrCNH: cnh,
      dataVencimentoCNH: cnhDueDate,
      dataVencimentoPesquisa: researcDate,
      idTipoMotorista: dataTypeValue === 'frota' ? 1 : 2,
      qtdPedidosMaxima: Number(maxOrders),
      usuarioCadastro: userId ?? 0,
      bairro: address?.neighborhood ?? '',
      cidade: address?.city ?? '',
      complemento: address?.complement ?? '',
      endereco: address?.street ?? '',
      numero: address?.number?.toString() ?? '',
      ativo: true,
    };

    setIsLoading(true);
    await dispatch(
      cadastrarMotorista({
        body,
      })
    )
      .unwrap()
      .then(() => {
        toast.success('Motorista cadastrado com sucesso!');
        handleClose();
        if (onUpdate) onUpdate();
      })
      .catch(() => {
        toast.error(
          'Ocorreu um erro ao realizar o cadastro do motorista, por favor tente mais tarde.'
        );
      })
      .finally(() => setIsLoading(false));
  };

  const handleEditDriver = async (data: FormSchema): Promise<void> => {
    if (!user || !driver) return;

    const { _Id: userId } = user;
    const {
      address,
      cnh,
      cnhDueDate,
      cpf: cpfValue,
      driverType: dataTypeValue,
      email,
      name,
      phone: phoneValue,
      researcDate,
      maxOrders,
    } = data;

    const body: IEditaMotoristaRequest = {
      idMotorista: driver.idMotorista,
      nome: name,
      email,
      cPF: cpfValue,
      telefone: phoneValue,
      nrCNH: cnh,
      dataVencimentoCNH: cnhDueDate,
      dataVencimentoPesquisa: researcDate,
      idTipoMotorista: dataTypeValue === 'frota' ? 1 : 2,
      qtdPedidosMaxima: Number(maxOrders),
      usuarioAlteracao: userId ?? 0,
      bairro: address?.neighborhood ?? '',
      cidade: address?.city ?? '',
      complemento: address?.complement ?? '',
      endereco: address?.street ?? '',
      numero: address?.number?.toString() ?? '',
    };

    setIsLoading(true);
    await dispatch(
      editarMotorista({
        body,
      })
    )
      .unwrap()
      .then(() => {
        toast.success('Motorista editado com sucesso!');
        handleClose();
        if (onUpdate) onUpdate();
      })
      .catch(() => {
        toast.error(
          'Ocorreu um erro ao realizar a alteração dos dados do motorista, por favor tente mais tarde.'
        );
      })
      .finally(() => setIsLoading(false));
  };

  const handleSubmitDriver = async (data: FormSchema): Promise<void> => {
    if (driver) handleEditDriver(data);
    else handleCreateDriver(data);
  };

  return {
    errors,
    handleSubmitDriver,
    handleClose,
    handleSubmit,
    isLoading,
    isOpen,
    isValid,
    onOpen,
    onOpenChange,
    register,
    theme,
    control,
    setValue,
    values,
  };
};

export default useRegisterDriverModal;

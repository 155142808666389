import {
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from '@nextui-org/react';
import React from 'react';
import { ITipoVeiculo } from '../../../../interfaces/response/TipoVeiculo/ITipoVeiculo';
import { IVeiculo } from '../../../../interfaces/response/Veiculo/IVeiculo';
import useVehicleTable, { Header } from './hooks/useVehicleTable';

export interface IVehicleTableProps {
  vehicles: IVeiculo[];
  onRowClick?: (locationPrice: IVeiculo) => void;
  vehicleTypeOptions: ITipoVeiculo[];
}

const VehicleTable: React.FC<IVehicleTableProps> = ({
  vehicles,
  onRowClick,
  vehicleTypeOptions,
}): JSX.Element => {
  const {
    cellData,
    columns,
    handleRowClick,
    setSortDescriptor,
    sortDescriptor,
    sortedVehicles,
  } = useVehicleTable({ vehicles, onRowClick, vehicleTypeOptions });

  return (
    <Table
      aria-label="Tabela de veículos"
      selectionMode="single"
      onRowAction={handleRowClick}
      sortDescriptor={sortDescriptor}
      onSortChange={setSortDescriptor}
    >
      <TableHeader columns={columns}>
        {({ key, name }) => (
          <TableColumn key={key} allowsSorting>
            {name}
          </TableColumn>
        )}
      </TableHeader>

      <TableBody
        items={sortedVehicles}
        emptyContent="Nenhum veículo no momento"
      >
        {(item) => (
          <TableRow key={item.idVeiculo}>
            {(columnKey) => (
              <TableCell>
                <p className="text-foreground-600">
                  {cellData(item, columnKey as Header)}
                </p>
              </TableCell>
            )}
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

export default VehicleTable;

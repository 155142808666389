import { Button, Input } from '@nextui-org/react';
import React from 'react';
import { BsEyeFill, BsEyeSlashFill } from 'react-icons/bs';
import useLogin from './hooks/useLogin';

export interface IProps {
  changeLayout: () => void;
}

const Login = ({ changeLayout }: IProps): JSX.Element => {
  const {
    handleSubmit,
    handleLogin,
    errors,
    register,
    isVisible,
    toggleVisibility,
    isLoading,
  } = useLogin({ changeLayout });

  return (
    <div>
      <form onSubmit={handleSubmit(handleLogin)} className="flex flex-col">
        <Input
          size="md"
          type="email"
          label="Email"
          variant="underlined"
          classNames={{
            label: 'font-bold',
          }}
          errorMessage={errors.email?.message}
          {...register('email')}
        />

        <Input
          size="md"
          type={isVisible ? 'text' : 'password'}
          label="Senha"
          variant="underlined"
          endContent={
            <button
              className="focus:outline-none"
              type="button"
              onClick={toggleVisibility}
            >
              {isVisible ? (
                <BsEyeSlashFill className="pointer-events-none text-2xl text-default-600" />
              ) : (
                <BsEyeFill className="pointer-events-none text-2xl text-default-600" />
              )}
            </button>
          }
          classNames={{
            label: 'font-bold',
          }}
          {...register('password')}
          errorMessage={errors.password?.message}
        />

        <Button
          type="submit"
          variant="solid"
          isLoading={isLoading}
          className="mt-8 w-full"
        >
          Entrar
        </Button>
      </form>
    </div>
  );
};

export default Login;

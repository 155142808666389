/* eslint-disable no-underscore-dangle */
import {
  Avatar,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Divider,
  Spacer,
} from '@nextui-org/react';
import React from 'react';
import { FaWarehouse } from 'react-icons/fa';
import { MdLogout } from 'react-icons/md';
import ChangePasswordModal from '../../components/ChangePasswordModal/ChangePasswordModal';
import { formatarCEP } from '../../util/mask';
import useAccount from './hooks/useAccount';

const Account = (): JSX.Element => {
  const { user, handleSignOut } = useAccount();

  return (
    <div className="flex grow flex-col">
      {/* Title */}
      <h1 className="text-2xl font-bold text-foreground-800">Minha Conta</h1>

      <Divider className="my-4" />

      {/* Card */}
      <Card className="min-w-min px-4 shadow-md">
        <CardBody className="flex flex-row items-center justify-between">
          {/* Name */}
          <div className="whitespace-nowrap text-foreground-800">
            <p>
              <span className="font-semibold">Nome: </span>
              {user?.name}
            </p>

            {/* E-mail */}
            <p>
              <span className="font-semibold">E-mail: </span>
              {user?.email}
            </p>
          </div>
        </CardBody>

        <CardFooter className="gap-4">
          {/* Change password */}
          <ChangePasswordModal />

          {/* Logout */}
          <Button
            onPress={handleSignOut}
            size="md"
            startContent={<MdLogout />}
            color="danger"
          >
            Sair
          </Button>
        </CardFooter>
      </Card>

      <Spacer y={8} />
      {/* Title */}
      <h1 className="text-3xl font-bold text-foreground-800">Acessos</h1>

      <Divider className="my-4" />

      <div className="grid grid-cols-4 gap-4">
        {/* Depositos */}
        {user?.customerAccess.depositos.map((deposito) => {
          return (
            <Card key={deposito.idDeposito} className="max-w-[400px]">
              <CardHeader className="flex gap-3">
                <Avatar
                  isBordered
                  color={
                    deposito.idDeposito === user.defaultWarehouse.idDeposito
                      ? 'success'
                      : 'default'
                  }
                  fallback={
                    <FaWarehouse
                      className="h-6 w-6 animate-pulse"
                      fill="currentColor"
                      size={20}
                    />
                  }
                />
                <div className="flex flex-col">
                  <p className="text-md">{`${deposito.descricao}`}</p>
                  <p className="text-small text-default-500">
                    {deposito.endereco.estado} - {deposito.endereco.cidade}
                  </p>
                </div>
              </CardHeader>
              <Divider />
              <CardBody>
                <p>
                  {deposito.endereco.logradouro}, {deposito.endereco.numero}
                </p>
                <p>
                  {deposito.endereco.bairro},{' '}
                  {formatarCEP(deposito.endereco.cep)}
                </p>
              </CardBody>
            </Card>
          );
        })}
      </div>
    </div>
  );
};

export default Account;

import { Selection } from '@nextui-org/react';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import {
  Theme,
  useThemeContext,
} from '../../../contexts/ThemeContext/ThemeContext';
import { Deposito } from '../../../interfaces/IUsuario';
import { putWarehouseExchange } from '../../../redux/features/clientsData/clientsDataThunk';
import { selectUsuario } from '../../../redux/features/generalData/generalDataSelectors';
import { refreshToken } from '../../../redux/features/generalData/generalDataThunk';
import { useAsyncDispatch } from '../../../redux/store';
import { ConfirmationModalHandle } from '../../ConfirmationModal/ConfirmationModal';

export interface UseWarehouseDropdown {
  theme: Theme;
  selectedWarehouse: Iterable<string>;
  onChange: (keys: Selection) => void;
  isLoading: boolean;
  warehouseOptions: Deposito[];
}

export interface UseWarehouseDropdownProps {
  confirmModalRef: React.RefObject<ConfirmationModalHandle>;
}

const useWarehouseDropdown = ({
  confirmModalRef,
}: UseWarehouseDropdownProps): UseWarehouseDropdown => {
  const { theme } = useThemeContext();
  const user = useSelector(selectUsuario);
  const dispatch = useAsyncDispatch();
  const [selectedWarehouse, setSelectedWarehouse] = useState<Iterable<string>>(
    []
  );
  const [warehouseOptions, setWarehouseOptions] = useState<Deposito[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const onChange = (keys: Selection): void => {
    if (!user) return;

    const selectedKeys = Array.from(keys);

    const auxWarehouse = warehouseOptions.find(
      (option) => option.idDeposito === Number(selectedKeys[0])
    );
    if (auxWarehouse) updateSelectedWarehouse(auxWarehouse);
  };

  const updateSelectedWarehouse = async (
    warehouse: Deposito
  ): Promise<void> => {
    if (!user) return;
    setIsLoading(true);
    if (confirmModalRef.current) {
      let userAcceptance = false;
      await confirmModalRef.current
        .confirmAction()
        .then(() => {
          userAcceptance = true;
        })
        .catch(() => {
          userAcceptance = false;
        });

      if (!userAcceptance) {
        setIsLoading(false);
        return;
      }
      setSelectedWarehouse([warehouse.idDeposito.toString()]);
      await dispatch(
        putWarehouseExchange({
          // eslint-disable-next-line no-underscore-dangle
          body: { idDeposito: warehouse.idDeposito, idUsuario: user._Id },
        })
      ).catch(() => {
        toast.error('Erro ao trocar o deposito do usuário');
        // eslint-disable-next-line no-useless-return
        return;
      });
      await dispatch(refreshToken({ email: user.email }))
        .then((res) => {
          toast.success('Deposito alterado com sucesso');
          window.location.reload();
        })
        .catch(() => toast.error('Erro ao trocar o deposito'));
    }

    setIsLoading(false);
  };

  const getWarehouses = async (): Promise<void> => {
    if (!user) return;

    setIsLoading(true);

    setSelectedWarehouse([user.defaultWarehouse.idDeposito.toString()]);
    setWarehouseOptions(user.customerAccess.depositos);

    setIsLoading(false);
  };

  useEffect(() => {
    getWarehouses();
  }, []);

  return { isLoading, onChange, selectedWarehouse, theme, warehouseOptions };
};

export default useWarehouseDropdown;

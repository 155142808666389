import { useLocation, useNavigate } from 'react-router-dom';

interface TabMenu {
  name: string;
  path: string;
}

export interface UseCadastrosLayout {
  handleRedirectTo: (path: string) => void;
  pathname: string;
  tabOptions: TabMenu[];
}

const useCadastrosLayout = (): UseCadastrosLayout => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const tabOptions: TabMenu[] = [
    {
      name: 'Motoristas',
      path: '/cadastros/motorista',
    },
    {
      name: 'Tabela Preço',
      path: '/cadastros/tabela-preco',
    },
    {
      name: 'Veículos',
      path: '/cadastros/veiculo',
    },
    {
      name: 'Tipo Veículo',
      path: '/cadastros/tipo-veiculo',
    },
  ];

  const handleRedirectTo = (path: string): void => {
    navigate(path);
  };

  return {
    handleRedirectTo,
    pathname,
    tabOptions,
  };
};

export default useCadastrosLayout;

import {
  Button,
  Card,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Radio,
  RadioGroup,
} from '@nextui-org/react';
import React from 'react';
import { Controller } from 'react-hook-form';
import { BiSolidEdit } from 'react-icons/bi';
import { FaPlusCircle } from 'react-icons/fa';
import { IMotorista } from '../../interfaces/response/Motorista/IMotorista';
import { formatarData } from '../../util/format';
import { cellphoneMask, cpfMask } from '../../util/mask';
import DatepickerInput from '../DatepickerInput/DatepickerInput';
import useRegisterDriverModal, {
  DriverType,
} from './hooks/useRegisterDriverModal';

interface RegisterDriverModalProps {
  driver?: IMotorista;
  onUpdate?: () => void;
}

const RegisterDriverModal = ({
  driver,
  onUpdate,
}: RegisterDriverModalProps): JSX.Element => {
  const {
    onOpen,
    isOpen,
    onOpenChange,
    theme,
    handleClose,
    handleSubmit,
    handleSubmitDriver,
    errors,
    register,
    isLoading,
    isValid,
    control,
    setValue,
    values,
  } = useRegisterDriverModal({ onUpdate, driver });

  return (
    <>
      <Button
        onPress={onOpen}
        size="md"
        startContent={<div>{driver ? <BiSolidEdit /> : <FaPlusCircle />}</div>}
      >
        <p>{driver ? 'Editar' : 'Cadastrar'} motorista</p>
      </Button>

      <Modal
        isOpen={isOpen}
        size="4xl"
        onOpenChange={onOpenChange}
        className={`${theme}`}
        onClose={handleClose}
      >
        <ModalContent>
          <form onSubmit={handleSubmit(handleSubmitDriver)}>
            <ModalHeader className="text-default-600">
              <p>{driver ? 'Editar' : 'Cadastrar'} motorista</p>
            </ModalHeader>

            <ModalBody>
              {/* User information */}
              {driver && (
                <Card
                  className="grid grid-cols-3 bg-foreground-100 p-4"
                  shadow="none"
                >
                  <div>
                    <p className="font-semibold">ID do motorista:</p>
                    <p className="text-default-600">{driver.idMotorista}</p>
                  </div>

                  <div>
                    <p className="font-semibold">Criado por:</p>
                    <p className="text-default-600">
                      {driver.nomeUsuarioCadastro} em{' '}
                      {formatarData(driver.dataCadastro)}
                    </p>
                  </div>

                  <div
                    className={`${driver.nomeUsuarioAlteracao ? '' : 'hidden'}`}
                  >
                    <p className="font-semibold">Alterado por:</p>
                    <p className="text-default-600">
                      {driver.nomeUsuarioAlteracao} em{' '}
                      {formatarData(driver.dataAlteracao)}{' '}
                    </p>
                  </div>
                </Card>
              )}

              {/* Tipo de motorista */}
              <Controller
                control={control}
                name="driverType"
                render={({ field }) => {
                  return (
                    <RadioGroup
                      orientation="horizontal"
                      onValueChange={(selectedValue) =>
                        setValue('driverType', selectedValue as DriverType)
                      }
                      {...field}
                      size="md"
                      label="Tipo do motorista"
                    >
                      <Radio value={DriverType.frota}>Frota</Radio>
                      <Radio value={DriverType.agregado}>Agregado</Radio>
                    </RadioGroup>
                  );
                }}
              />

              {/* Inputs Grid */}
              <div className="grid grid-cols-2 gap-4">
                {/* Name */}
                <Input
                  size="md"
                  type="text"
                  label="Nome"
                  variant="underlined"
                  classNames={{
                    label: 'font-bold',
                  }}
                  value={values.name}
                  errorMessage={errors.name?.message}
                  {...register('name')}
                />

                {/* E-mail */}
                <Input
                  size="md"
                  type="text"
                  label="Email"
                  variant="underlined"
                  classNames={{
                    label: 'font-bold',
                  }}
                  value={values.email}
                  errorMessage={errors.email?.message}
                  {...register('email')}
                />

                {/* CPF */}
                <Input
                  size="md"
                  type="text"
                  label="CPF"
                  variant="underlined"
                  classNames={{
                    label: 'font-bold',
                  }}
                  errorMessage={errors.cpf?.message}
                  {...register('cpf')}
                  value={values.cpf}
                  onValueChange={(value) => {
                    const maskedValue = cpfMask(value);
                    setValue('cpf', maskedValue);
                  }}
                />

                {/* Phone */}
                <Input
                  size="md"
                  type="text"
                  label="Telefone"
                  variant="underlined"
                  classNames={{
                    label: 'font-bold',
                  }}
                  value={values.phone}
                  errorMessage={errors.phone?.message}
                  {...register('phone')}
                  onValueChange={(value) => {
                    const maskedValue = cellphoneMask(value);
                    setValue('phone', maskedValue);
                  }}
                />

                {/* CNH */}
                <Input
                  maxLength={11}
                  size="md"
                  type="text"
                  label="CNH"
                  variant="underlined"
                  classNames={{
                    label: 'font-bold',
                  }}
                  value={values.cnh}
                  errorMessage={errors.cnh?.message}
                  {...register('cnh')}
                />

                {/* Max orders */}
                <Input
                  size="md"
                  type="number"
                  label="Quantidade máxima de pedidos"
                  variant="underlined"
                  classNames={{
                    label: 'font-bold',
                  }}
                  value={values.maxOrders}
                  errorMessage={errors.maxOrders?.message}
                  {...register('maxOrders')}
                />

                {/* CNH due date */}
                <DatepickerInput
                  control={control}
                  name="cnhDueDate"
                  inputProps={{
                    size: 'md',
                    label: 'Data de vencimento CNH',
                    variant: 'underlined',
                    errorMessage: errors.cnhDueDate?.message,
                  }}
                  datePickerProps={{
                    minDate: new Date(),
                  }}
                />

                {/* Research due date */}
                <DatepickerInput
                  control={control as any}
                  name="researcDate"
                  inputProps={{
                    size: 'md',
                    label: 'Data da pesquisa',
                    variant: 'underlined',
                    errorMessage: errors.researcDate?.message,
                  }}
                  datePickerProps={{
                    minDate: new Date(),
                  }}
                />

                {/* Address */}
                <Input
                  size="md"
                  type="text"
                  label="Endereço"
                  variant="underlined"
                  className={`${
                    values.driverType === DriverType.frota ? 'hidden' : ''
                  }`}
                  classNames={{
                    label: 'font-bold',
                  }}
                  value={values.address?.street}
                  errorMessage={errors.address?.street?.message}
                  {...register('address.street')}
                />

                {/* Number */}
                <Input
                  size="md"
                  type="text"
                  label="Número"
                  variant="underlined"
                  className={`${
                    values.driverType === DriverType.frota ? 'hidden' : ''
                  }`}
                  classNames={{
                    label: 'font-bold',
                  }}
                  value={values.address?.number}
                  errorMessage={errors.address?.number?.message}
                  {...register('address.number')}
                />

                {/* Neighborhood */}
                <Input
                  size="md"
                  type="text"
                  label="Bairro"
                  variant="underlined"
                  className={`${
                    values.driverType === DriverType.frota ? 'hidden' : ''
                  }`}
                  classNames={{
                    label: 'font-bold',
                  }}
                  value={values.address?.neighborhood}
                  errorMessage={errors.address?.neighborhood?.message}
                  {...register('address.neighborhood')}
                />

                {/* City */}
                <Input
                  size="md"
                  type="text"
                  label="Cidade"
                  variant="underlined"
                  className={`${
                    values.driverType === DriverType.frota ? 'hidden' : ''
                  }`}
                  classNames={{
                    label: 'font-bold',
                  }}
                  value={values.address?.city}
                  errorMessage={errors.address?.city?.message}
                  {...register('address.city')}
                />

                {/* Complement */}
                <Input
                  size="md"
                  type="text"
                  label="Complemento (Opcional)"
                  variant="underlined"
                  className={`${
                    values.driverType === DriverType.frota ? 'hidden' : ''
                  }`}
                  classNames={{
                    label: 'font-bold',
                  }}
                  value={values.address?.complement}
                  errorMessage={errors.address?.complement?.message}
                  {...register('address.complement')}
                />
              </div>
            </ModalBody>

            <ModalFooter>
              {/* Cancel Button */}
              <Button color="danger" onPress={handleClose}>
                Cancelar
              </Button>

              {/* Save Button */}
              <Button type="submit" isLoading={isLoading} isDisabled={!isValid}>
                <p>{driver ? 'Alterar' : 'Cadastrar'}</p>
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
};

export default RegisterDriverModal;

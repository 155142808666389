import { SortDescriptor } from '@nextui-org/react';
import {
  Dispatch,
  Key,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { ITabelaPreco } from '../../../../../interfaces/response/TabelaPreco/ITabelaPreco';
import { ITipoVeiculo } from '../../../../../interfaces/response/TipoVeiculo/ITipoVeiculo';
import { formatarData } from '../../../../../util/format';
import { cepMask, moneyMask } from '../../../../../util/mask';

export type Header =
  | 'idTabelaPreco'
  | 'cepInicio'
  | 'cepFinal'
  | 'valor'
  | 'idTipoVeiculo'
  | 'ativo'
  | 'dataCadastro';

type Column = {
  key: Header;
  name: string;
};

export interface UseLocationPriceTableProps {
  locationsPrices: ITabelaPreco[];
  onRowClick?: (locationPrice: ITabelaPreco) => void;
  vehicleTypeOptions: ITipoVeiculo[];
}

export interface UseLocationPriceTable {
  handleRowClick: (key: Key) => void;
  sortDescriptor: SortDescriptor;
  setSortDescriptor: Dispatch<SetStateAction<SortDescriptor>>;
  columns: Column[];
  sortedLocationsPrices: ITabelaPreco[];
  cellData: (locationPrice: ITabelaPreco, columnKey: Header) => number | string;
}

const useLocationPriceTable = ({
  locationsPrices,
  onRowClick,
  vehicleTypeOptions,
}: UseLocationPriceTableProps): UseLocationPriceTable => {
  const [sortDescriptor, setSortDescriptor] = useState<SortDescriptor>({
    column: 'idTabelaPreco',
    direction: 'descending',
  });

  const sortedLocationsPrices = useMemo(() => {
    return [...locationsPrices].sort((a: ITabelaPreco, b: ITabelaPreco) => {
      const first = a[sortDescriptor.column as keyof ITabelaPreco] as number;
      const second = b[sortDescriptor.column as keyof ITabelaPreco] as number;

      let cmp = 0;
      if (first < second) cmp = -1;
      if (first > second) cmp = 1;

      return sortDescriptor.direction === 'ascending' ? -cmp : cmp;
    });
  }, [sortDescriptor, locationsPrices]);

  const columns: Column[] = [
    {
      key: 'idTabelaPreco',
      name: 'ID',
    },
    {
      key: 'cepInicio',
      name: 'CEP DE INÍCIO',
    },
    {
      key: 'cepFinal',
      name: 'CEP FINAL',
    },
    {
      key: 'valor',
      name: 'VALOR',
    },
    {
      key: 'idTipoVeiculo',
      name: 'TIPO DE VEÍCULO',
    },
    {
      key: 'ativo',
      name: 'ATIVO',
    },
    {
      key: 'dataCadastro',
      name: 'DATA DE CADASTRO',
    },
  ];

  const cellData = useCallback(
    (locationPrice: ITabelaPreco, columnKey: Header): number | string => {
      switch (columnKey) {
        case 'idTabelaPreco':
          return locationPrice.idTabelaPreco;
        case 'cepInicio':
          return cepMask(locationPrice.cepInicio);
        case 'cepFinal':
          return cepMask(locationPrice.cepFinal);
        case 'valor':
          return moneyMask(locationPrice.valor, 'R$', 2);
        case 'idTipoVeiculo':
          return (
            vehicleTypeOptions.find(
              (vehicleAux) =>
                vehicleAux.idTipoVeiculo === locationPrice.idTipoVeiculo
            )?.categoria ?? locationPrice.idTipoVeiculo
          );
        case 'ativo':
          return locationPrice.ativo ? 'Sim' : 'Não';
        case 'dataCadastro':
          return formatarData(locationPrice.dataCadastro, true) ?? '';
        default:
          return '';
      }
    },
    [vehicleTypeOptions]
  );

  const handleRowClick = (key: Key): void => {
    const locationPrice = locationsPrices.find(
      (locationPriceAux) => locationPriceAux.idTabelaPreco.toString() === key
    );
    if (onRowClick && locationPrice) onRowClick(locationPrice);
  };

  return {
    cellData,
    columns,
    handleRowClick,
    setSortDescriptor,
    sortDescriptor,
    sortedLocationsPrices,
  };
};

export default useLocationPriceTable;

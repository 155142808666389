export function JWTValidate(jwt: string): boolean {
  // Verifica se o JWT está no formato válido
  const jwtParts = jwt.split('.');
  if (jwtParts.length !== 3) {
    throw new Error('Invalid JWT format');
  }

  // Decodifica a parte do payload do JWT (segunda parte)
  const payloadBase64 = jwtParts[1];
  let payload: any;
  try {
    payload = JSON.parse(atob(payloadBase64));
  } catch (err) {
    throw new Error('Invalid JWT payload');
  }

  // Verifica a existência e a validade da propriedade 'exp' no payload
  const expirationTime = payload.exp;
  if (typeof expirationTime !== 'number' || Number.isNaN(expirationTime)) {
    throw new Error('Invalid expiration time');
  }

  // Obtém a data atual em segundos
  const currentTimeInSeconds = Math.floor(Date.now() / 1000);
  // Compara a data de expiração com a data atual e retorna o resultado
  return expirationTime >= currentTimeInSeconds;
}

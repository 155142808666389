/* eslint-disable react/no-array-index-key */
import { Card, ScrollShadow, Spacer, Spinner } from '@nextui-org/react';
import React from 'react';
import AddVehicleModal from '../../components/AddVehicleModal/AddVehicleModal';
import MercosulPlateDropdown from '../../components/MercosulPlateDropdown/MercosulPlateDropdown';
import RegisterDriverModal from '../../components/RegisterDriverModal/RegisterDriverModal';
import { formatarData } from '../../util/format';
import { formatarTelefone, mascararDocumento } from '../../util/mask';
import useDetalheMotorista from './hooks/useDetalheMotorista';

export interface IModalAttrs {
  title: string;
  children: JSX.Element;
}
const DetalheMotorista = (): JSX.Element => {
  const {
    driver,
    vehicleOptions,
    driverVehicles,
    loadingDriver,
    loadingVehicles,
    handleGetDriver,
    handleGetRemainingVehicles,
    removeVehicle,
    setVehicleAsDefault,
  } = useDetalheMotorista();

  if (loadingDriver)
    return <Spinner label="Baixando conteúdo" color="default" />;

  return (
    <div className="flex flex-col">
      <div className="flex flex-row items-center justify-between">
        <p className="text-2xl font-semibold text-foreground-700">
          {driver?.nome}
        </p>

        <RegisterDriverModal driver={driver} onUpdate={handleGetDriver} />
      </div>

      <Spacer y={2} />

      {/* Driver */}
      {driver && (
        <Card
          className="grid grid-cols-3 gap-4 bg-foreground-100 p-4"
          shadow="none"
        >
          {/* Email */}
          <div>
            <p className="font-semibold">Email:</p>
            <p className="text-default-600">{driver.email}</p>
          </div>

          {/* Phone */}
          <div>
            <p className="font-semibold">Telefone:</p>
            <p className="text-default-600">
              {formatarTelefone(driver.telefone)}
            </p>
          </div>

          {/* Type */}
          <div>
            <p className="font-semibold">Tipo:</p>
            <p className="text-default-600">
              {driver.idTipoMotorista === 1 ? 'Frota' : 'Agregado'}
            </p>
          </div>

          {/* CPF */}
          <div>
            <p className="font-semibold">CPF:</p>
            <p className="text-default-600">
              {mascararDocumento(1, driver.cpf)}
            </p>
          </div>

          {/* CNH */}
          <div>
            <p className="font-semibold">CNH:</p>
            <p className="text-default-600">{driver.nrCNH}</p>
          </div>

          {/* CNH Duedate */}
          <div>
            <p className="font-semibold">Vencimento CNH:</p>
            <p className="text-default-600">
              {formatarData(driver.dataVencimentoCNH, true)}
            </p>
          </div>

          {/* Research Duedate */}
          <div>
            <p className="font-semibold">Vencimento Pesquisa:</p>
            <p className="text-default-600">
              {formatarData(driver.dataVencimentoPesquisa, true)}
            </p>
          </div>

          {/* Max Orders */}
          <div>
            <p className="font-semibold">Quantidade Maxima de Pedidos:</p>
            <p className="text-default-600">{driver.qtdPedidosMaxima}</p>
          </div>
        </Card>
      )}

      {driver?.idTipoMotorista === 2 && (
        <p className="mb-1 mt-4 text-lg font-semibold text-foreground-700">
          Endereço
        </p>
      )}

      {/* Adress */}
      {driver?.idTipoMotorista === 2 && (
        <Card
          className="grid grid-cols-3 gap-4 bg-foreground-100 p-4"
          shadow="none"
        >
          {/* Address */}
          <div>
            <p className="font-semibold">Logradouro:</p>
            <p className="text-default-600">{driver.endereco}</p>
          </div>

          {/* Number */}
          <div>
            <p className="font-semibold">Número:</p>
            <p className="text-default-600">{driver.numero}</p>
          </div>

          {/* Neighborhood */}
          <div>
            <p className="font-semibold">Bairro:</p>
            <p className="text-default-600">{driver.bairro}</p>
          </div>

          {/* City */}
          <div>
            <p className="font-semibold">Cidade:</p>
            <p className="text-default-600">{driver.cidade}</p>
          </div>

          {/* Complement */}
          <div>
            <p className="font-semibold">Complemento:</p>
            <p className="text-default-600">
              {driver.complemento === '' ? '---' : driver.complemento}
            </p>
          </div>
        </Card>
      )}

      <Spacer y={4} />

      {vehicleOptions && (
        <div>
          {/* Vehicles Title */}
          <div className="flex flex-row items-center justify-between">
            <div className="flex h-10 flex-row items-center justify-center gap-4">
              <p className="mb-1 text-lg font-semibold text-foreground-700">
                Veículos
              </p>

              {loadingVehicles && <Spinner color="default" />}
            </div>

            {/* Add Vehicles Button */}
            <AddVehicleModal
              onUpdate={handleGetRemainingVehicles}
              idMotorista={driver?.idMotorista ?? 0}
              vehicleOptions={vehicleOptions}
            />
          </div>

          <p className="text-sm text-foreground-700">
            Clique na placa do veículo para torna-lo padrão ou remove-lo
          </p>

          {/* Plates */}
          <ScrollShadow
            orientation="horizontal"
            className="flex w-full flex-row gap-x-4"
          >
            {driverVehicles?.map((veiculo) => (
              <div className="mt-4" key={veiculo.idVeiculoMotorista}>
                {/* Dropdown */}
                <div className="flex h-[100px] items-center justify-center">
                  <MercosulPlateDropdown
                    driverVehicle={veiculo}
                    onRemove={() => removeVehicle(veiculo)}
                    onSetAsDefault={() => setVehicleAsDefault(veiculo)}
                  />
                </div>

                {/* Date */}
                <div className="ml-2 mt-4">
                  <p className="font-semibold text-default-600">Desde:</p>
                  <p className="text-default-600">
                    {formatarData(veiculo.dataAtribuicao)}
                  </p>
                </div>
              </div>
            ))}
          </ScrollShadow>
        </div>
      )}
    </div>
  );
};

export default DetalheMotorista;

import { Button, Input, Spacer, Switch, Tooltip } from '@nextui-org/react';
import React from 'react';
import { IoMdInformationCircleOutline } from 'react-icons/io';
import PrintedOrderTable from './components/PrintedOrderTable/PrintedOrderTable';
import PrintOrderTagModal from './components/PrintOrderTagModal/PrintOrderTagModal';
import useCheckOrders from './hooks/useCheckOrders';

const CheckOrders = (): JSX.Element => {
  const {
    disableAutoSubmit,
    setDisableAutoSubmit,
    submitToPrinter,
    theme,
    navigate,
    setNfKey,
    nfKey,
    printModalRef,
    addPrintedOrder,
    printedOrdersArray,
    openModalWithTag,
  } = useCheckOrders();

  return (
    <div>
      {/* Description and Switch */}
      <div className="flex flex-row flex-wrap items-center justify-between gap-4">
        <div className="flex flex-row gap-2">
          {/* Description */}
          <p className="text-md text-default-800">
            Entre com a chave da nota fiscal para marcar o pedido como conferido
            e imprimir a etiqueta do pedido:
          </p>

          {/* Tooltip */}
          <Tooltip
            className={theme}
            content={
              <div className="text-foreground-700">
                <p className="p-2">
                  Para imprimir pedidos conferidos anteriormente vá para
                  <br />o menu{' '}
                  <span className="font-semibold">Gerir Rotas</span> na aba{' '}
                  <span className="font-semibold">A conferir</span>{' '}
                  <button
                    type="button"
                    className="cursor-pointer text-blue-700"
                    onClick={() => navigate('/rotas/a-conferir')}
                    onKeyDown={() => navigate('/rotas/a-conferir')}
                  >
                    (Ou clique aqui)
                  </button>
                </p>
              </div>
            }
          >
            <div>
              <IoMdInformationCircleOutline className="h-6 w-6 text-foreground-400" />
            </div>
          </Tooltip>
        </div>

        {/* Automatic disable */}
        <Switch
          isSelected={disableAutoSubmit}
          onValueChange={setDisableAutoSubmit}
        >
          Desabilitar conferência automática
        </Switch>
      </div>

      <Spacer y={4} />

      {/* Form */}
      <form
        onSubmit={(event) => {
          event.preventDefault();
          submitToPrinter(false);
        }}
        className="flex w-full flex-row items-center justify-center gap-4"
      >
        {/* Input */}
        <Input
          size="lg"
          label="Chave da nota Fiscal"
          variant="flat"
          maxLength={44}
          labelPlacement="outside-left"
          classNames={{
            label: 'font-bold whitespace-nowrap',
            base: 'items-center justify-center w-auto',
            mainWrapper: 'w-[620px]',
            input: 'font-mono text-2xl text-center p-3',
          }}
          value={nfKey}
          onValueChange={setNfKey}
        />

        <Button
          type="submit"
          size="lg"
          color="primary"
          isDisabled={!disableAutoSubmit || nfKey.length !== 44}
        >
          Conferir
        </Button>
      </form>

      {/* Print Tag Modal */}
      <PrintOrderTagModal ref={printModalRef} onTagPrinted={addPrintedOrder} />

      <Spacer y={12} />

      {/* Verified Orders List */}
      <PrintedOrderTable
        printedOrders={printedOrdersArray}
        onPrintOrder={openModalWithTag}
      />
    </div>
  );
};

export default CheckOrders;

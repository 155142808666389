import { AxiosError } from 'axios';
import { useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { Deposito, Endereco } from '../../../../../interfaces/IUsuario';
import {
  IDetalheRotaRequest,
  Waypoint,
} from '../../../../../interfaces/request/DetalheRota/IDetalheRotaRequest';
import { IDetalhesRota } from '../../../../../interfaces/response/DetalheRota/IDetalheRotaResponse';
import { IMotorista } from '../../../../../interfaces/response/Motorista/IMotorista';
import { IPedidosRotasResponse } from '../../../../../interfaces/response/Pedido/IPedidosRotasResponse';
import { IVeiculoMotorista } from '../../../../../interfaces/response/VeiculoMotorista/IVeiculoMotorista';
import { CalculaDetalhesRota } from '../../../../../redux/features/clientsData/clientsDataThunk';
import { selectReturnToWarehouse } from '../../../../../redux/features/generalData/generalDataSelectors';
import {
  setReturnToWarehouse,
  setRouteDetails,
  setRouteDetailsLoading,
} from '../../../../../redux/features/generalData/generalDataSlice';
import { useAsyncDispatch } from '../../../../../redux/store';
import getRandomHexColor from '../../../../../util/colorGenerator';
import { moneyMask } from '../../../../../util/mask';
import { RouteError } from '../../../hooks/useCreateRoutes';

interface UseCreatedRouteDetailsProps {
  selectedOrders: IPedidosRotasResponse[];
  warehouse: Deposito | undefined;
  backToWarehouse: boolean;
  vehicle?: IVeiculoMotorista;
  driver?: IMotorista;
  onErrors: (errors: Set<RouteError>) => void;
}

export interface UseCreatedRouteDetails {
  numberOfOrders: number;
  totalWeight: number;
  loadingDetails: boolean;
  totalValue: number;
  totalRouteTime: number | undefined;
  totalRouteDistance: number | undefined;
  driverMaxNumberOrdersError: boolean;
  vehicleWeightExceedError: boolean;
  vehicleValueExceedError: boolean;
}

const useCreatedRouteDetails = ({
  selectedOrders,
  backToWarehouse,
  warehouse,
  driver,
  vehicle,
  onErrors,
}: UseCreatedRouteDetailsProps): UseCreatedRouteDetails => {
  const dispatch = useAsyncDispatch();
  const [details, setDetails] = useState<IDetalhesRota | undefined>(undefined);
  const [loadingDetails, setLoadingDetails] = useState<boolean>(false);
  const [routeErrors, setRouteErrors] = useState<RouteError[]>([]);
  const numberOfOrders = selectedOrders.reduce(
    (acc, order) => acc + order.pedido.volumes,
    0
  );
  const returnToWarehouse = useSelector(selectReturnToWarehouse);

  const totalWeight = selectedOrders.reduce(
    (total, current) => total + current.pedido.pesoReal,
    0
  );
  const totalValue = selectedOrders.reduce(
    (total, current) => total + current.pedido.vlCargaAverbado,
    0
  );
  const totalRouteTime = details?.duracaoTotalSegundos;
  const totalRouteDistance = details?.trajetoTotalMetros;

  const getRouteDetails = async (): Promise<void> => {
    if (!warehouse) return;

    setLoadingDetails(true);
    await dispatch(
      setRouteDetailsLoading({
        loading: true,
      })
    );
    const waypoints: Waypoint[] = selectedOrders.map(
      (order) =>
        ({
          idCte: order.pedido.idCte,
          vlTotalTributo: order.pedido.vlTotalTributo,
          vlCarga: order.pedido.vlCarga,
          vlCargaAverbado: order.pedido.vlCargaAverbado,
          pesoReal: order.pedido.pesoReal,
          unidadeMedidaPesoReal: order.pedido.unidadeMedidaPesoReal,
          pesoCubado: order.pedido.pesoCubado,
          unidadeMedidaPesoCubado: order.pedido.unidadeMedidaPesoCubado,
          pesoTaxado: order.pedido.pesoTaxado,
          unidadeMedidaPesoTaxado: order.pedido.unidadeMedidaPesoTaxado,
          adValorem: order.pedido.adValorem,
          fretePeso: order.pedido.fretePeso,
          gRIS: order.pedido.gris,
          impostoRepassado: order.pedido.impostoRepassado,
          vlTotalPrestacao: order.pedido.vlTotalPrestacao,
          vlTotalReceber: order.pedido.vlTotalReceber,
          vTotTribICMS: order.pedido.vTotTribICMS,
          volumes: order.pedido.volumes,
          volume: order.pedido.volume,
          unidadeMedidaVolume: order.pedido.unidadeMedidaVolume,
          chaveNFe: order.pedido.chaveNFe,
          dtEmissao: order.pedido.dtEmissao,
          dtLimiteEntrega: order.pedido.dtLimiteEntrega,
          logradouro: order.destinatario.logradouro,
          numero: order.destinatario.numero,
          complemento: order.destinatario.complemento,
          bairro: order.destinatario.bairro,
          cidade: order.destinatario.cidade,
          estado: order.destinatario.estado,
          lat: order.destinatario.lat,
          lng: order.destinatario.lng,
          cep: order.destinatario.cep,
        } as Waypoint)
    );

    let destino: Endereco = warehouse.endereco;
    const origem: Endereco = warehouse.endereco;

    if (!returnToWarehouse)
      destino = {
        idEndereco: '',
        logradouro: driver?.endereco || '',
        numero: driver?.numero || '',
        complemento: driver?.complemento || '',
        bairro: driver?.bairro || '',
        cidade: driver?.cidade || '',
        estado: '',
        lat: driver?.lat || '',
        lng: driver?.lng || '',
        cep: '',
      };

    const body: IDetalheRotaRequest = {
      idCliente: warehouse.idCliente,
      idDeposito: warehouse.idDeposito,
      dataSaida: new Date(),
      descricao: 'TESTE',
      idMotoristaVeiculo: vehicle?.idVeiculoMotorista || 0,
      destino,
      origem,
      pedidos: waypoints,
      voltaParaDeposito: returnToWarehouse,
      polylineColor: getRandomHexColor(),
    };

    await dispatch(
      CalculaDetalhesRota({
        body,
      })
    )
      .unwrap()
      .then(async (response) => {
        setDetails(response);
        await dispatch(
          setRouteDetails({
            routeDetails: response,
          })
        );
      })
      .catch((error) => {
        const axiosError = error as AxiosError;
        if (axiosError.code === 'ERR_CANCELED') return;

        toast.error(
          'Erro ao consultar detalhes da rota, por favor tente mais tarde'
        );
      })
      .finally(async () => {
        setLoadingDetails(false);
        await dispatch(
          setRouteDetailsLoading({
            loading: false,
          })
        );
      });
  };

  const driverMaxNumberOrdersError = useMemo((): boolean => {
    setRouteErrors((errors) =>
      errors.filter((error) => error.errorCode !== 'driverMaxOrders')
    );

    if (
      !driver ||
      (numberOfOrders * 100) / driver.qtdPedidosMaxima <= 100 ||
      driver.qtdPedidosMaxima === 0
    )
      return false;

    setRouteErrors((errors) => [
      ...errors,
      {
        errorCode: 'driverMaxOrders',
        message: `Número máximo de pedidos do motorista excedido em ${
          numberOfOrders - driver.qtdPedidosMaxima
        }`,
      },
    ]);

    return true;
  }, [selectedOrders, driver]);

  const vehicleWeightExceedError = useMemo((): boolean => {
    setRouteErrors((errors) =>
      errors.filter((error) => error.errorCode !== 'vehicleWeight')
    );

    if (!vehicle || (totalWeight * 100) / vehicle.veiculo.peso <= 100)
      return false;

    setRouteErrors((errors) => [
      ...errors,
      {
        errorCode: 'vehicleWeight',
        message: `Peso máximo de pedidos do veículo excedido em ${moneyMask(
          totalWeight - vehicle.veiculo.peso,
          '',
          2
        )} kg`,
      },
    ]);

    return true;
  }, [selectedOrders, vehicle]);

  const vehicleValueExceedError = useMemo((): boolean => {
    setRouteErrors((errors) =>
      errors.filter((error) => error.errorCode !== 'vehicleValue')
    );

    if (!vehicle || (totalValue * 100) / vehicle.veiculo.valor <= 100)
      return false;

    setRouteErrors((errors) => [
      ...errors,
      {
        errorCode: 'vehicleValue',
        message: `Valor máximo de pedidos do veículo excedido em ${moneyMask(
          totalValue - vehicle.veiculo.valor,
          'R$',
          2
        )}`,
      },
    ]);

    return true;
  }, [selectedOrders, vehicle]);

  useEffect(() => {
    if (!driver) dispatch(setReturnToWarehouse(true));
  }, []);

  useEffect(() => {
    getRouteDetails();
  }, [selectedOrders, returnToWarehouse]);

  useEffect(() => {
    onErrors(new Set(routeErrors));
  }, [routeErrors]);

  return {
    loadingDetails,
    numberOfOrders,
    totalRouteDistance,
    totalRouteTime,
    totalValue,
    totalWeight,
    driverMaxNumberOrdersError,
    vehicleWeightExceedError,
    vehicleValueExceedError,
  };
};

export default useCreatedRouteDetails;

import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import { IRota } from '../../../../interfaces/response/Rotas/IRota';
import {
  approveRoute,
  getRouteDetails,
  rejectRoute,
} from '../../../../redux/features/rotas/rotasThunk';
import { useAsyncDispatch } from '../../../../redux/store';

interface UseDetalheRotaProps {
  route: IRota | undefined;
  loading: boolean;
  handleApproveRoute: (idRota: string) => Promise<void>;
  loadingApproval: boolean;
  handleRejectRoute: (idRota: string) => Promise<void>;
  loadingReject: boolean;
}

const UseDetalheRota = (): UseDetalheRotaProps => {
  const { id: routeId } = useParams();
  const [route, setRoute] = useState<IRota>();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingApproval, setLoadingApproval] = useState<boolean>(false);
  const [loadingReject, setLoadingReject] = useState<boolean>(false);
  const dispatch = useAsyncDispatch();
  const navigate = useNavigate();

  const getRoute = async (): Promise<void> => {
    if (!routeId) return;

    setLoading(true);
    await dispatch(getRouteDetails({ id: routeId }))
      .unwrap()
      .then((res) => {
        setRoute(res.rota);
        toast.success('rota consultada com sucesso');
      })
      .catch((err) => {
        toast.success('erro ao consultar a rota');
      })
      .finally(() => setLoading(false));
  };

  const handleApproveRoute = async (): Promise<void> => {
    if (!routeId) return;

    setLoadingApproval(true);
    await dispatch(approveRoute({ id: routeId }))
      .unwrap()
      .then(async (res) => {
        toast.success('rota aprovada com sucesso');
        navigate('/rotas/agurdando-aprovacao');
      })
      .catch((err) => {
        toast.success('erro ao aprovar a rota');
      })
      .finally(() => setLoadingApproval(false));
  };

  const handleRejectRoute = async (): Promise<void> => {
    if (!routeId) return;

    setLoadingReject(true);
    await dispatch(rejectRoute({ id: routeId }))
      .unwrap()
      .then((res) => {
        toast.success('rota cancelada com sucesso');

        navigate('/rotas/agurdando-aprovacao');
      })
      .catch((err) => {
        toast.success('erro ao cancelar a rota');
      })
      .finally(() => setLoadingReject(false));
  };

  useEffect(() => {
    getRoute();
  }, []);

  return {
    route,
    loading,
    handleApproveRoute,
    handleRejectRoute,
    loadingApproval,
    loadingReject,
  };
};

export default UseDetalheRota;

import { useSelector } from 'react-redux';
import { User } from '../../../interfaces/IUsuario';
import { selectUsuario } from '../../../redux/features/generalData/generalDataSelectors';
import { logOut } from '../../../redux/features/generalData/generalDataSlice';
import { useAsyncDispatch } from '../../../redux/store';

export interface IuseAccount {
  user: User | undefined;
  handleSignOut: () => void;
}

const useAccount = (): IuseAccount => {
  const dispatch = useAsyncDispatch();

  const user = useSelector(selectUsuario);

  const handleSignOut = (): void => {
    dispatch(logOut());
  };

  return {
    user,
    handleSignOut,
  };
};

export default useAccount;

import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectUsuario } from '../../../redux/features/generalData/generalDataSelectors';
import { logOut } from '../../../redux/features/generalData/generalDataSlice';
import { useAsyncDispatch } from '../../../redux/store';
import { JWTValidate } from '../../../util/validator';

export interface UseApplicationLayout {
  menuColapsed: boolean;
  setMenuColapsed: Dispatch<SetStateAction<boolean>>;
}

const useApplicationLayout = (): UseApplicationLayout => {
  const [menuColapsed, setMenuColapsed] = useState<boolean>(true);
  const dispatch = useAsyncDispatch();
  const user = useSelector(selectUsuario);

  useEffect(() => {
    const tokenValidate = (): void => {
      if (
        user === undefined ||
        user.accessToken.token === undefined ||
        !JWTValidate(user.accessToken.token)
      ) {
        dispatch(logOut());
      }
    };

    tokenValidate();
  }, []);

  return {
    menuColapsed,
    setMenuColapsed,
  };
};

export default useApplicationLayout;

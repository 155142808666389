import axios from 'axios';

export const MiddlewareAPI = axios.create({
  baseURL: process.env.REACT_APP_URL_BASE_BFF,
  timeout: 300000,
});

export const setMiddlewareAPIToken = (jwtToken: string): void => {
  MiddlewareAPI.defaults.headers.common.Authorization = `Bearer ${jwtToken}`;
};

// ########   V2      ########//

export const AuthSchemaAPI = axios.create({
  baseURL: process.env.REACT_APP_URL_BASE_AUTHSCHEMA,
  timeout: 300000,
});

export const RoteirizadorAPI = axios.create({
  baseURL: process.env.REACT_APP_URL_BASE_ROTEIRIZADOR,
  timeout: 300000,
});

export const CustomerAPI = axios.create({
  baseURL: process.env.REACT_APP_URL_BASE_CUSTOMER,
  timeout: 300000,
});

export const OrderAPI = axios.create({
  baseURL: process.env.REACT_APP_URL_BASE_ORDER,
  timeout: 300000,
});

export const RoutesAPI = axios.create({
  baseURL: process.env.REACT_APP_URL_BASE_ROUTES,
  timeout: 300000,
});

export const setAuthSchemaAPIToken = (jwtToken: string): void => {
  AuthSchemaAPI.defaults.headers.common.Authorization = `Bearer ${jwtToken}`;
};
export const setRoteirizadorAPIToken = (jwtToken: string): void => {
  RoteirizadorAPI.defaults.headers.common.Authorization = `Bearer ${jwtToken}`;
};
export const setCustomerAPIToken = (jwtToken: string): void => {
  CustomerAPI.defaults.headers.common.Authorization = `Bearer ${jwtToken}`;
};
export const setOrderAPIToken = (jwtToken: string): void => {
  OrderAPI.defaults.headers.common.Authorization = `Bearer ${jwtToken}`;
};
export const setRoutesAPIToken = (jwtToken: string): void => {
  RoutesAPI.defaults.headers.common.Authorization = `Bearer ${jwtToken}`;
};

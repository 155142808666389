import { SortDescriptor } from '@nextui-org/react';
import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import {
  formatarMedidaGenerico,
  formatarMoeda,
} from '../../../../../../util/mask';

type Column = {
  key: any;
  name: string;
};

interface IPedido {
  idRotaPedido: string;
  idRota: string;
  idCte: string;
  volumesConferidos: number;
  vlTotalTributo: number;
  vlCarga: number;
  vlCargaAverbado: number;
  pesoReal: number;
  unidadeMedidaPesoReal: string;
  pesoCubado: number;
  unidadeMedidaPesoCubado: string;
  pesoTaxado: number;
  unidadeMedidaPesoTaxado: string;
  adValorem: number;
  fretePeso: number;
  gris: number;
  impostoRepassado: number;
  vlTotalPrestacao: number;
  vlTotalReceber: number;
  vTotTribICMS: number;
  volumes: number;
  volume: number;
  unidadeMedidaVolume: string;
  chaveNFe: string;
  dtEmissao: Date;
  dtLimiteEntrega: Date;
  logradouro: string;
  numero: string;
  complemento: null;
  bairro: string;
  cidade: string;
  estado: string;
  lat: string;
  lng: string;
  cep: string;
  status: number;
  ordem: number;
}

export interface UseOrderListTableProps {
  orders: IPedido[];
}

export interface UseOrderListTable {
  cellData: (order: IPedido, columnKey: any) => number | string;
  columns: Column[];
  setSortDescriptor: Dispatch<SetStateAction<SortDescriptor>>;
  sortDescriptor: SortDescriptor;
  sortedOrders: IPedido[];
}

const useOrderListTable = ({
  orders,
}: UseOrderListTableProps): UseOrderListTable => {
  const [sortDescriptor, setSortDescriptor] = useState<SortDescriptor>({
    column: 'Ordem de entrega',
    direction: 'descending',
  });

  const columns: Column[] = [
    {
      key: 'Ordem de entrega',
      name: 'ORDEM DE ENTREGA',
    },
    {
      key: 'DANFE',
      name: 'DANFE',
    },
    {
      key: 'Valor',
      name: 'VALOR',
    },
    {
      key: 'Volume',
      name: 'VOLUME',
    },
    {
      key: 'Endereço',
      name: 'ENDEREÇO',
    },
    {
      key: 'Conferidos',
      name: 'CONFIRMADO',
    },
  ];

  const cellData = (order: IPedido, columnKey: any): number | string => {
    switch (columnKey) {
      case 'Ordem de entrega':
        return order.ordem;
      case 'Volume':
        return formatarMedidaGenerico(order.volumes, 'M3');
      case 'Endereço':
        return `${order.logradouro}, ${order.numero}, ${order.bairro}, ${order.cidade}`;
      case 'Peso':
        return formatarMedidaGenerico(order.pesoReal, 'Kg');
      case 'Valor':
        return formatarMoeda(order.vlTotalReceber);
      case 'Conferidos':
        return `${order.volumesConferidos}/${order.volumes}`;
      case 'DANFE':
        return order.chaveNFe;
      default:
        return '';
    }
  };

  const sortedOrders = useMemo(() => {
    return [...orders].sort((a: IPedido, b: IPedido) => {
      const first = cellData(a, sortDescriptor.column as any) as number;
      const second = cellData(b, sortDescriptor.column as any) as number;

      let cmp = 0;
      if (first < second) cmp = -1;
      if (first > second) cmp = 1;

      return sortDescriptor.direction === 'ascending' ? -cmp : cmp;
    });
  }, [sortDescriptor, orders]);

  return {
    cellData,
    columns,
    setSortDescriptor,
    sortDescriptor,
    sortedOrders,
  };
};

export default useOrderListTable;

/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable consistent-return */
/* eslint-disable import/no-unresolved */
import { FC, useEffect, useRef, useState } from 'react';
import { createRoot, Root } from 'react-dom/client';
import { IPedidosRotasResponse } from '../../../../interfaces/response/Pedido/IPedidosRotasResponse';

const DrawingManager: FC<{
  map: google.maps.Map;
  points: IPedidosRotasResponse[];
  onPolygonCompleteAdd: (insidePoints: IPedidosRotasResponse[]) => void;
  onPolygonCompleteDelete: (insidePoints: IPedidosRotasResponse[]) => void;
}> = ({ map, points, onPolygonCompleteAdd, onPolygonCompleteDelete }) => {
  const drawerRef = useRef<google.maps.drawing.DrawingManager>();
  const rootRef = useRef<Root>();
  const polygonRef = useRef<google.maps.Polygon | null>(null);
  const [drawingMode, setDrawingMode] =
    useState<google.maps.drawing.OverlayType | null>(null);
  const [drawingColor, setDrawingColor] = useState<string>('#00FF00'); // Default to green

  const handleKeyDown = (event: KeyboardEvent): void => {
    if (event.key === 'A' || event.key === 'a') {
      setDrawingMode(google.maps.drawing.OverlayType.POLYGON);
      setDrawingColor('#00FF00'); // Green for adding
    } else if (event.key === 'D' || event.key === 'd') {
      setDrawingMode(google.maps.drawing.OverlayType.POLYGON);
      setDrawingColor('#FF0000'); // Red for removing
    }
  };

  const handleKeyUp = (event: KeyboardEvent): void => {
    if (
      event.key === 'A' ||
      event.key === 'a' ||
      event.key === 'D' ||
      event.key === 'd'
    ) {
      setDrawingMode(null);
    }
  };

  useEffect(() => {
    const drawerContainer = document.createElement('div');
    drawerContainer.setAttribute('id', 'rendered');
    rootRef.current = createRoot(drawerContainer);

    const initialState = new google.maps.drawing.DrawingManager({
      drawingMode: null,
      drawingControl: false,
      map,
      polygonOptions: {
        strokeColor: drawingColor,
        fillColor: drawingColor,
      },
    });

    drawerRef.current = initialState;

    google.maps.event.addListener(
      initialState,
      'polygoncomplete',
      (polygon: any) => {
        polygonRef.current = polygon;

        const insidePoints = points.filter((point) =>
          google.maps.geometry.poly.containsLocation(
            new google.maps.LatLng(
              parseFloat(point.destinatario.lat || '0'),
              parseFloat(point.destinatario.lng || '0')
            ),
            polygon
          )
        );

        if (drawingColor === '#00FF00') {
          onPolygonCompleteAdd(insidePoints);
        } else if (drawingColor === '#FF0000') {
          onPolygonCompleteDelete(insidePoints);
        }

        polygon.setMap(null); // Remove the polygon from the map after checking
      }
    );

    return () => {
      if (drawerRef.current) {
        drawerRef.current.setMap(null);
      }
    };
  }, [
    map,
    points,
    onPolygonCompleteAdd,
    onPolygonCompleteDelete,
    drawingColor,
  ]);

  useEffect(() => {
    if (!drawerRef.current) return;

    drawerRef.current.setOptions({
      drawingMode,
      polygonOptions: {
        strokeColor: drawingColor,
        fillColor: drawingColor,
      },
    });

    drawerRef.current.setMap(map);
  }, [map, drawingMode, drawingColor]);

  useEffect(() => {
    const handleKeyDownWrapper = (event: KeyboardEvent): void =>
      handleKeyDown(event);
    const handleKeyUpWrapper = (event: KeyboardEvent): void =>
      handleKeyUp(event);

    document.addEventListener('keydown', handleKeyDownWrapper);
    document.addEventListener('keyup', handleKeyUpWrapper);

    return () => {
      document.removeEventListener('keydown', handleKeyDownWrapper);
      document.removeEventListener('keyup', handleKeyUpWrapper);
    };
  }, []);

  return null;
};

export default DrawingManager;

import { User } from '../../../interfaces/IUsuario';
import { IRotaDetails } from '../../../interfaces/response/Rotas/IRotaDetails';
import { RootState } from '../../store';

export const selectGeneralData = (state: RootState): string | undefined =>
  state.generalData.generalData;

export const selectGeneralDataStatus = (state: RootState): string | undefined =>
  state.generalData.generalDataStatus;

export const selectLoading = (state: RootState): boolean =>
  state.generalData.loading;

export const selectUsuario = (state: RootState): User | undefined =>
  state.generalData.usuario;

export const selectAuthAttributs = (
  state: RootState
): 'LoggedIn' | 'LoggedOut' => state.generalData.auth;

export const selectReturnToWarehouse = (state: RootState): boolean =>
  state.generalData.returnToWarehouse;

export const selectOrdersDate = (
  state: RootState
): {
  startOrdersDate: Date;
  endOrdersDate: Date;
  searchType: '0' | '1';
} => state.generalData.ordersDate;

export const selectRoutesDate = (
  state: RootState
): {
  startRoutesDate: Date;
  endRoutesDate: Date;
  searchType: '0' | '1';
} => state.generalData.routesDate;

export const selectRouteDetails = (state: RootState): IRotaDetails =>
  state.generalData.routeDetails;

import React from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import { ReactComponent as Flag } from '../../assets/brazil-flag-icon.svg';

interface MercosulPlateProps {
  plate: string;
  isSelected: boolean;
}

const MercosulPlate: React.FC<MercosulPlateProps> = ({ plate, isSelected }) => {
  return (
    <div
      className={`${
        isSelected ? 'rounded-xl border-4 border-lime-500' : 'm-1'
      }`}
    >
      <div className="relative rounded-lg border-4 border-gray-800 bg-white pb-2 text-center shadow-md">
        {/* Check */}
        {isSelected && (
          <div className="absolute -top-4 left-1 z-10 rounded-full bg-white">
            <FaCheckCircle className="h-6 w-6 text-lime-500" />
          </div>
        )}

        {/* Stripe */}
        <div className="flex h-6 items-end rounded-t-sm bg-blue-700 shadow-md">
          <div className="absolute flex h-6 w-full items-center justify-end">
            <Flag className="m-1 h-auto w-6 rounded-[3px] border border-white" />
          </div>

          {/* Country name */}
          <p className="w-full text-xs font-semibold text-white">BRASIL</p>
        </div>

        {/* Plate */}
        <p className="mx-4 font-mono text-5xl font-bold text-gray-800">
          {plate}
        </p>

        {/* Country code */}
        <div className="absolute bottom-0 flex w-full justify-start">
          <p className="mx-1 text-xs font-semibold text-gray-800">BR</p>
        </div>
      </div>
    </div>
  );
};

export default MercosulPlate;

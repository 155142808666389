import { Card, Tooltip } from '@nextui-org/react';
import React, { useContext } from 'react';
import { IoMdInformationCircleOutline } from 'react-icons/io';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  ThemeContext,
  ThemeContextType,
} from '../../../../contexts/ThemeContext/ThemeContext';
import { selectOrdersDate } from '../../../../redux/features/generalData/generalDataSelectors';
import { formatarData } from '../../../../util/format';

const SelectedPeriodCard = (): JSX.Element => {
  const { startOrdersDate, endOrdersDate } = useSelector(selectOrdersDate);
  const { theme } = useContext<ThemeContextType>(ThemeContext);
  const navigate = useNavigate();

  return (
    <Card className="flex flex-row items-center gap-2 p-2 text-sm" radius="sm">
      <p>
        Mostrando pedidos importados entre{' '}
        <span className="font-semibold">
          {formatarData(startOrdersDate, true, 'normal')}
        </span>{' '}
        e{' '}
        <span className="font-semibold">
          {formatarData(endOrdersDate, true, 'normal')}
        </span>
      </p>

      {/* Tooltip */}
      <Tooltip
        offset={10}
        className={theme}
        content={
          <div className="text-foreground-700">
            <p className="p-2">
              Para imprimir pedidos conferidos anteriormente vá para
              <br />o menu <span className="font-semibold">Pedidos</span>{' '}
              <button
                type="button"
                className="cursor-pointer text-blue-700"
                onClick={() => navigate('/gestao-de-pedidos')}
                onKeyDown={() => navigate('/gestao-de-pedidos')}
              >
                (Ou clique aqui)
              </button>
            </p>
          </div>
        }
      >
        <div>
          <IoMdInformationCircleOutline className="h-5 w-5 text-foreground-400" />
        </div>
      </Tooltip>
    </Card>
  );
};

export default SelectedPeriodCard;

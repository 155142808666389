import { Tooltip } from '@nextui-org/react';
import { motion } from 'framer-motion';
import React, { FC } from 'react';
import { ReactComponent as GreenPin } from '../../../../assets/GreenPin.svg';
import { ReactComponent as RedPin } from '../../../../assets/RedPin.svg';
import { Deposito } from '../../../../interfaces/IUsuario';
import { IDetalhesRota } from '../../../../interfaces/response/DetalheRota/IDetalheRotaResponse';
import { IPedidosRotasResponse } from '../../../../interfaces/response/Pedido/IPedidosRotasResponse';
import DrawingManager from '../DrawingManager/DrawingManager';
import Marker from '../Marker/Marker';
import Polyline from '../Polyline/Polyline';
import TootipCard from '../TootipCard/TootipCard';
import WarehouseTooltipCard from '../WarehouseTooltipCard/WarehouseTooltipCard';

export interface OrderLocationsProps {
  map: google.maps.Map;
  orders: IPedidosRotasResponse[];
  warehouse: Deposito | undefined;
  toggleOrderSelection: (order: IPedidosRotasResponse) => void;
  toggleOrderSelectionManyAdd: (orders: IPedidosRotasResponse[]) => void;
  toggleOrderSelectionManyDelete: (orders: IPedidosRotasResponse[]) => void;
  selectedOrders: IPedidosRotasResponse[];
  routeDetails: IDetalhesRota | undefined;
  strokeColor: string;
}

const OrderLocations: FC<OrderLocationsProps> = ({
  map,
  orders,
  toggleOrderSelection,
  toggleOrderSelectionManyAdd,
  toggleOrderSelectionManyDelete,
  selectedOrders,
  warehouse,
  routeDetails,
  strokeColor,
}): JSX.Element => {
  return (
    <>
      {/* Warehouse Marker */}
      <Marker
        key="warehouse"
        map={map}
        position={{
          lat: parseFloat(warehouse?.endereco.lat || ''),
          lng: parseFloat(warehouse?.endereco.lng || ''),
        }}
      >
        <Tooltip
          content={<WarehouseTooltipCard warehouse={warehouse} />}
          showArrow
        >
          <motion.div
            initial={{ y: -100, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{
              delay: Math.random(),
              duration: 0.2,
              ease: 'easeInOut',
            }}
          >
            <motion.button
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
            >
              <img
                width="40"
                height="40"
                src="https://img.icons8.com/color/48/garage-closed.png"
                alt="garage-closed"
              />
            </motion.button>
          </motion.div>
        </Tooltip>
      </Marker>

      {/* Order Markers */}
      {orders.map((order) => (
        <Marker
          key={order.pedido.idCte}
          map={map}
          position={{
            lat: Number(order.destinatario.lat),
            lng: Number(order.destinatario.lng),
          }}
          onClick={() => toggleOrderSelection(order)}
        >
          <Tooltip content={<TootipCard order={order} />} showArrow>
            <motion.div
              initial={{ y: -100, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{
                delay: 0.1 + Math.random() * (1.5 - 0.1),
                duration: 0.2,
                ease: 'easeInOut',
              }}
            >
              <motion.button
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
              >
                {selectedOrders.includes(order) ? (
                  <GreenPin className="h-8 w-8" />
                ) : (
                  <RedPin className="h-8 w-8" />
                )}
              </motion.button>
            </motion.div>
          </Tooltip>
        </Marker>
      ))}

      {routeDetails?.polylines.map((polilyne) => (
        <Polyline
          key={polilyne.toString()}
          path={polilyne}
          map={map}
          strokeColor={strokeColor}
        />
      ))}

      {/* Drawing Manager */}
      <DrawingManager
        points={orders}
        onPolygonCompleteAdd={(obj) => {
          toggleOrderSelectionManyAdd(obj);
        }}
        onPolygonCompleteDelete={(obj) => {
          toggleOrderSelectionManyDelete(obj);
        }}
        key="drawer"
        map={map}
      />
    </>
  );
};

export default OrderLocations;

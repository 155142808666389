import { RefObject, useEffect, useRef, useState } from 'react';
import { Deposito } from '../../../../../interfaces/IUsuario';

export interface UseCreateRouteMap {
  mapContainerRef: RefObject<HTMLDivElement>;
  map: google.maps.Map | undefined;
}

const useCreateRouteMap = ({
  warehouse,
}: {
  warehouse: Deposito | undefined;
}): UseCreateRouteMap => {
  const [map, setMap] = useState<google.maps.Map>();
  const mapContainerRef = useRef<HTMLDivElement>(null);

  const mapOptions: google.maps.MapOptions = {
    mapId: process.env.REACT_APP_MAP_ID,
    center: {
      lat: parseFloat(warehouse?.endereco.lat || ''),
      lng: parseFloat(warehouse?.endereco.lng || ''),
    },
    zoom: 12,
    disableDefaultUI: true,
  };

  useEffect(() => {
    if (!mapContainerRef.current) return;

    setMap(new window.google.maps.Map(mapContainerRef.current, mapOptions));
  }, [mapContainerRef.current]);

  return { map, mapContainerRef };
};

export default useCreateRouteMap;

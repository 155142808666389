import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Spinner,
} from '@nextui-org/react';
import React, {
  forwardRef,
  ForwardRefRenderFunction,
  useImperativeHandle,
} from 'react';
import { IOrderCheckResponse } from '../../../../interfaces/response/Orders/IOrderCheckResponse';
import OrderTag from './components/OrderTag/OrderTag';
import { usePrintOrderTagModal } from './hooks/usePrintOrderTagModal';

export interface IPrintOrderTagModalHandle {
  openModalToPrint: (nfKey: string, auto: boolean) => void;
  openModal: (printedTag: IOrderCheckResponse) => void;
  closeModal: () => void;
}

interface IPrintOrderTagModalProps {
  onTagPrinted?: (tagData: IOrderCheckResponse) => void;
}

const PrintOrderTagModal: ForwardRefRenderFunction<
  IPrintOrderTagModalHandle,
  IPrintOrderTagModalProps
> = ({ onTagPrinted }, ref): JSX.Element => {
  const {
    isOpen,
    onOpenChange,
    theme,
    handleOpenModal,
    handleOpenModalToShowTag,
    handleCloseModal,
    loading,
    handlePrint,
    tagData,
    tagRef,
  } = usePrintOrderTagModal({ onTagPrinted });

  useImperativeHandle(ref, () => ({
    openModalToPrint: (nfKey: string, auto: boolean) => {
      handleOpenModal(nfKey, auto);
    },
    openModal: (printedTag: IOrderCheckResponse) => {
      handleOpenModalToShowTag(printedTag);
    },
    closeModal: () => {
      handleCloseModal();
    },
  }));

  return (
    <Modal
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      className={`${theme}`}
      onClose={handleCloseModal}
    >
      <ModalContent>
        {/* Header */}
        <ModalHeader className="text-default-600">
          Imprimir etiqueta
        </ModalHeader>

        {/* Body */}
        <ModalBody>
          {/* Loading Spinner */}
          {tagData === null && <Spinner />}

          {/* Information text */}
          {tagData && (
            <p className="text-sm text-default-700">
              Imprimir etiqueta do pedido{' '}
              <span className="font-bold">número da nota do pedido</span>?
            </p>
          )}

          {/* Order Table */}
          <div className="flex justify-center">
            <div className="rounded-xl border border-foreground-300">
              {tagData && (
                <div
                  ref={tagRef}
                  className="h-[7.5cm] w-[10cm] overflow-hidden rounded-xl"
                >
                  <OrderTag tagData={tagData} />
                </div>
              )}
            </div>
          </div>
        </ModalBody>

        {/* Footer */}
        <ModalFooter>
          <Button color="danger" onPress={handleCloseModal}>
            Cancelar
          </Button>

          <Button type="button" onPress={handlePrint} isLoading={loading}>
            Imprimir
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default forwardRef(PrintOrderTagModal);

import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
} from '@nextui-org/react';
import React, { FC, useContext } from 'react';
import { FaCheckCircle, FaMinusCircle } from 'react-icons/fa';
import {
  ThemeContext,
  ThemeContextType,
} from '../../contexts/ThemeContext/ThemeContext';
import { IVeiculoMotorista } from '../../interfaces/response/VeiculoMotorista/IVeiculoMotorista';
import MercosulPlate from '../MercosulPlate/MercosulPlate';

interface MercosulPlateDropdownProps {
  driverVehicle: IVeiculoMotorista;
  onSetAsDefault: () => void;
  onRemove: () => void;
}

const MercosulPlateDropdown: FC<MercosulPlateDropdownProps> = ({
  onRemove,
  onSetAsDefault,
  driverVehicle: { veiculo, veiculoPadrao },
}): JSX.Element => {
  const { theme } = useContext<ThemeContextType>(ThemeContext);

  return (
    <Dropdown placement="bottom-end" className={theme}>
      <DropdownTrigger>
        <div className="cursor-pointer">
          <MercosulPlate plate={veiculo.placa} isSelected={veiculoPadrao} />
        </div>
      </DropdownTrigger>

      <DropdownMenu
        aria-label="Ações de usuário"
        variant="flat"
        disabledKeys={veiculoPadrao ? ['add'] : []}
      >
        <DropdownItem
          key="add"
          color="success"
          className="p-4 text-foreground-700"
          startContent={<FaCheckCircle />}
          onPress={onSetAsDefault}
        >
          Tornar este veículo padrão
        </DropdownItem>

        <DropdownItem
          key="remove"
          color="danger"
          className="p-4 text-foreground-700"
          startContent={<FaMinusCircle />}
          onPress={onRemove}
        >
          Remover
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

export default MercosulPlateDropdown;
